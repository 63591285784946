import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { politicsModel } from "../apollo/ContextApp";
import { GET_POLITICS } from "./querys";

const useGetPolitics = () => {
  const [politics, setPolitics] = useState(politicsModel);
  const { data: dP, loading: lDP } = useQuery(GET_POLITICS);

  useEffect(() => {
    if (dP && dP.getPolitics) {
      if (dP.getPolitics.response.code === 200) {
        setPolitics(dP.getPolitics);
      }
    }
  }, [dP]);

  return { politics, loading: lDP };
};
export default useGetPolitics;
