import React from "react";

import { voteModel } from "../../apollo/ContextApp";

export const reducer = (state, action) => {};

export const Context = React.createContext({
  vote: {
    ...voteModel,
  },
  topic: {
    ...voteModel,
  },
  reducer: () => {},
  margeVote: () => {},
  loading: false,
});
