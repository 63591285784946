import React from "react";

import "bootstrap/dist/js/bootstrap.bundle.min";

/*
const Menu = (props) => {
  const { language, name, subMenus } = props;

  return (
    <div className="accordion accordion-flush" id={`id-${name}`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#id-flush-${name}`}
            aria-expanded="false"
            aria-controls={`#id-flush-${name}`}
          >
            {language.txt.votaciones}
          </button>
        </h2>
        <div
          id={`id-flush-${name}`}
          className="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent={`#id-${name}`}
        >
          <div className="accordion-body">
            <ul className="list-group">
              {subMenus.map((menu) => (
                <a
                  href={menu.url}
                  className="list-group-item list-group-item-action bg-transparent border-0"
                >
                  <p className="mb-1">{menu.text}</p>
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
*/

const LMenu = (props) => {
  const { language, userLoggedIn, location } = props;

  return (
    <div className="min-vh-100">
      <div className="list-group mt-5">
        {userLoggedIn.rol.users.active ? (
          <a
            href="/usuarios"
            className="list-group-item list-group-item-action bg-transparent border-0"
          >
            <i
              className={`bi-person-circle iconLM ${
                location.pathname === "/usuarios"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            ></i>
            <span
              className={`ms-3 fw-bold textLM ${
                location.pathname === "/usuarios"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {language.txt.usuarios}
            </span>
          </a>
        ) : (
          ""
        )}

        {userLoggedIn.rol.voting.active ? (
          <a
            href="/votaciones"
            className="list-group-item list-group-item-action bg-transparent border-0"
          >
            <i
              className={`bi bi-hand-thumbs-up-fill iconLM ${
                location.pathname === "/votaciones"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            ></i>
            <span
              className={`ms-3 fw-bold textLM ${
                location.pathname === "/votaciones"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {language.txt.votaciones}
            </span>
          </a>
        ) : (
          ""
        )}

        {userLoggedIn.rol.parliamentary.active ? (
          <a
            href="/parlamentaria"
            className="list-group-item list-group-item-action bg-transparent border-0 d-flex"
          >
            <i
              className={`bi bi-x-diamond-fill iconLM me-3 ${
                location.pathname === "/parlamentaria"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            ></i>

            <span
              className={`fw-bold textLM ${
                location.pathname === "/parlamentaria"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {language.txt.InfoEstParlamentaria}
            </span>
          </a>
        ) : (
          ""
        )}

        {userLoggedIn.rol.congressTopic.active ? (
          <a
            href="/temas-congreso"
            className="list-group-item list-group-item-action bg-transparent border-0"
          >
            <i
              className={`bi bi-person-lines-fill iconLM ${
                location.pathname === "/temas-congreso"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            ></i>
            <span
              className={`ms-3 fw-bold textLM ${
                location.pathname === "/temas-congreso"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {language.txt.temasCongreso}
            </span>
          </a>
        ) : (
          ""
        )}

        {userLoggedIn.rol.politics.active ? (
          <a
            href="/politicas"
            className="list-group-item list-group-item-action bg-transparent border-0"
          >
            <i
              className={`bi bi-bookmark-check-fill iconLM ${
                location.pathname === "/politicas"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            ></i>
            <span
              className={`ms-3 fw-bold textLM ${
                location.pathname === "/politicas"
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {language.txt.politicas}
            </span>
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default LMenu;
