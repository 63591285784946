import React from "react";

import styles from "./styles.module.scss";
import useUI from "../../Hooks/useUI";
const SMenu = (props) => {
  const { userLoggedIn, language, history } = props;
  const { ui, updateUI } = useUI();

  const exitSession = () => {
    localStorage.clear();
    history.push("/login");
  };

  const handleLMenu = () => {
    updateUI({
      ...ui,
      lMenu: !ui.lMenu,
    });
  };

  return (
    <header>
      <nav
        className={`navbar navbar-expand-md navbar-white bg-white fixed-top ${styles.header}`}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <a className="navbar-brand" href="/">
            <img src={ui.urlImg} alt="Votaciones" width="70" height="auto" />
          </a>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i
                className={`bi-list ${styles.menu_ico}`}
                onClick={handleLMenu}
              ></i>
            </div>
            <div
              className="btn-group"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <div className="btn-group dropstart" role="group">
                <button
                  id="btnGroupDrop1"
                  type="button"
                  className="btn mr-5"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="">
                    <img
                      src={userLoggedIn.icon}
                      alt={userLoggedIn.names}
                      width="30"
                      height="auto"
                      className="me-2"
                    />
                    <span className="fw-bold">{userLoggedIn.names}</span>
                  </div>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <li>
                    <a
                      className="dropdown-item list-group-item-action d-flex justify-content-between align-items-center"
                      href="/profile"
                    >
                      <span>{language.txt.miperfil}</span>
                      <span className="ms-5">
                        <i className="bi-person-circle"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider text-muted" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item list-group-item-action d-flex justify-content-between align-items-center"
                      href="/login"
                      onClick={exitSession}
                    >
                      <span>{language.txt.cerrarSesion}</span>
                      <span className="ms-5">
                        <i className="bi-toggle2-off"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SMenu;
