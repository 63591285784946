import React from "react";

import { InputSelect } from "../inputs/Inputs";

export const CatStatus = (props) => {
  const { language, onChange, value } = props;
  return (
    <InputSelect
      name="status"
      label={language.txt.status}
      options={[
        { text: language.txt.inactiva, value: "INACTIVE" },
        { text: language.txt.borrador, value: "DRAF" },
        { text: language.txt.publicada, value: "PUBLISH" },
      ]}
      onChange={onChange}
      value={value}
    />
  );
};

export const CatApprovalStatus = (props) => {
  const { language, onChange, value } = props;
  return (
    <InputSelect
      name="approvalStatus"
      label={language.txt.approvalStatus}
      options={[
        { text: language.txt.seleccionar, value: "UNKNOWN" },
        { text: language.txt.aprobada, value: "APPROVED" },
        { text: language.txt.noAprobada, value: "NOTAPPROVED" },
      ]}
      onChange={onChange}
      value={value}
    />
  );
};

export const TypeUsers = (props) => {
  const { language, onChange, value, userLoggedIn } = props;

  let options = [];

  if (userLoggedIn.type == "ADMIN") {
    options = [
      { text: language.txt.seleccionar, value: "UNKNOWN" },
      { text: language.txt.administrador, value: "ADMIN" },
      { text: language.txt.editor, value: "EDITOR" },
      { text: language.txt.votantegeneral, value: "GENERALVOTER" },
      { text: language.txt.votante, value: "VOTER" },
    ];
  } else if (
    userLoggedIn.type == "EDITOR" ||
    userLoggedIn.type == "GENERALVOTER"
  ) {
    options = [
      { text: language.txt.seleccionar, value: "UNKNOWN" },
      { text: language.txt.votante, value: "EDITOR" },
      { text: language.txt.votante, value: "VOTER" },
    ];
  } else if (userLoggedIn.type == "VOTER") {
    options = [
      { text: language.txt.seleccionar, value: "UNKNOWN" },
      { text: language.txt.votante, value: "VOTER" },
    ];
  }
  return (
    <InputSelect
      name="type"
      label={language.txt.tipo}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export const CatUserStatus = (props) => {
  const { language, onChange, value } = props;
  return (
    <InputSelect
      name="status"
      label={language.txt.status}
      options={[
        { text: language.txt.seleccionar, value: "UNKNOWN" },
        { text: language.txt.inactiva, value: "INACTIVE" },
        { text: language.txt.activo, value: "ACTIVE" },
      ]}
      onChange={onChange}
      value={value}
    />
  );
};

export const CatTimeZone = (props) => {
  const { language, onChange, value } = props;
  let options = [
    {
      value: "",
      text: language.txt.seleccionar,
    },
    {
      value: -8,
      text: "UTC-08:00,U",
    },
    {
      value: -7,
      text: "UTC-07:00,T",
    },
    {
      value: -6,
      text: "UTC-06:00,S",
    },
    {
      value: -5,
      text: "UTC-05:00,R",
    },
    {
      value: -4,
      text: "UTC-04:00,Q",
    },
    {
      value: -3,
      text: "UTC-03:00,P",
    },
    {
      value: -2,
      text: "UTC-02:00,O",
    },
    {
      value: -1,
      text: "UTC-01:00,N",
    },
    {
      value: -0,
      text: "UTC±00:00,T",
    },
    {
      value: 1,
      text: "UTC+01:00,A",
    },
    {
      value: 2,
      text: "UTC+02:00,B",
    },
    {
      value: 3,
      text: "UTC+03:00,C",
    },
    {
      value: 4,
      text: "UTC+04:00,D",
    },
    {
      value: 5,
      text: "UTC+05:00,E",
    },
    {
      value: 6,
      text: "UTC+06:00,F",
    },
    {
      value: 7,
      text: "UTC+07:00,G",
    },
  ];
  return (
    <InputSelect
      name="timeZone"
      label={language.txt.zonaHoraria}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export const CatNextProcess = (props) => {
  const { language, onChange, value } = props;
  return (
    <InputSelect
      name="nextProcess"
      label={language.txt.tramiteSiguiente}
      options={[
        { text: language.txt.seleccionar, value: "UNKNOWN" },
        { text: language.txt.vaParaDiputados, value: "TODEPUTY" },
        { text: language.txt.vaAlSenado, value: "TOSENATORS" },
        { text: language.txt.dof, value: "TODOF" },
      ]}
      onChange={onChange}
      value={value}
    />
  );
};

export const CatTurns = (props) => {
  const { language, onChange, value } = props;
  return (
    <InputSelect
      name="turn"
      label={language.txt.camaraOrigen}
      options={[
        { text: language.txt.seleccionar, value: "UNKNOWN" },
        { text: language.txt.camarDiputados, value: "DEPUTY" },
        { text: language.txt.camarSenadores, value: "SENATOR" },
      ]}
      onChange={onChange}
      value={value}
    />
  );
};
