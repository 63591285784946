import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Components APP
import styles from "./styles.module.scss";
import SMenu from "./navigation/SMenu";
import LMenu from "./navigation/LMenu";
import useUI from "../Hooks/useUI";
import { LoadingScreen } from "../components/messages/Messages";

const withContainer = (Component) => (config) => (props) => {
  const { userLoggedIn } = props;
  const { ui } = useUI();
  const [loading, setLoading] = useState(true);
  const RouterComponenet = withRouter(Component);
  const RouterSMenu = withRouter(SMenu);
  const RouterLMenu = withRouter(LMenu);

  useEffect(() => {
    if (userLoggedIn.uidUser !== "") {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setLoading(false);
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
      }, 1000);
    }
  }, [userLoggedIn]);

  return (
    <>
      <RouterSMenu {...props} />

      <LoadingScreen userLoggedIn={userLoggedIn} loading={loading} />

      <div className={`container-fluid mt-5`}>
        <div className="row">
          <div
            className={`${
              ui.lMenu ? styles.showLM : styles.hideLM
            } border-end pt-5`}
          >
            <RouterLMenu {...props} />
          </div>
          <div className={`${ui.lMenu ? styles.main : styles.mainAll} pt-5`}>
            <RouterComponenet {...props} {...config} />
          </div>
        </div>
      </div>
    </>
  );
};

//${ui.lMenu ? styles.showLM : styles.hideLM}
//${ui.lMenu ? styles.showMain : styles.hideMain}

export default withContainer;
