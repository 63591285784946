import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import styles from "./styles.module.scss";
import withContainer from "../../withContainer";
import InputFileDragAndDropImg from "../../../components/inputs/InputFileDragAndDropImg";
import { Accordion } from "../../../components/accordion/Accordion";
import { MessageAction } from "../../../components/messages/Messages";
import PersonalInformation from "./PersonalInformation";
import ChangePassword from "../profile/ChangePassword";
import Roles from "./Roles";
import { userModel } from "../../../apollo/ContextApp";
import { Context, reducer } from "./Context";

import useUpload from "../../../Hooks/useUpload";
import useFindUserUid from "../../../Hooks/useFindUserUid";
import { EDIT_PI, CHANGE_AVATAR, EDIT_ROL } from "./mutations";

const { REACT_APP_URL_IMG_DEFAULT } = process.env;
const { Provider } = Context;

const initialFile = {
  file: undefined,
  response: { code: 0, message: "" },
  url: "-",
  name: "",
};

const EditUser = (props) => {
  const { language } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });
  const [user, setUser] = useState(userModel);
  const { user: dataUser, loading: lUser } = useFindUserUid({
    uidUser: props.match.params.id,
  });
  const [msnImg, setMsnImg] = useState({ message: "", code: 200 });
  const [fileImg, setFileImg] = useState(initialFile);
  const [rqChangeAvatar, { loading: lCA }] = useMutation(CHANGE_AVATAR, {
    onCompleted: ({ changeAvatar }) => {
      setMsnImg({ ...changeAvatar });
    },
  });

  const { upload, loading: lUpload } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setFileImg({
          ...fileImg,
          url: uploadFile.url,
        });
        rqChangeAvatar({
          variables: {
            icon: uploadFile.url,
            uidUser: user.uidUser,
          },
        });
      }
      setMsnImg({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnImg({ message: "", code: 200 });
      }, 3000);
    },
  });

  const [rqEditPI, { loading: lEPI }] = useMutation(EDIT_PI, {
    onCompleted: ({ editPersonalInformation }) => {
      setLoading(false);
      setMessage({
        ...editPersonalInformation,
      });
      setTimeout(() => {
        setMessage({
          message: "",
          code: 200,
        });
      }, 3000);
    },
  });

  const [rqEditRol, { loading: lRol }] = useMutation(EDIT_ROL, {
    onCompleted: ({ editRol }) => {
      setLoading(false);
      setMessage({
        ...editRol,
      });
      setTimeout(() => {
        setMessage({
          message: "",
          code: 200,
        });
      }, 3000);
    },
  });

  const handleImage = (files) => {
    setMsnImg({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
      setFileImg({
        file: files[0],
        response: { code: 0, message: "" },
        url: "-",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setFileImg({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnImg({ message: language.txt.errorformatoArchivoImg, code: 401 });
    }
  };

  const handleUpdateUser = (e) => {
    const { value, name } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleUpdateUserRol = (rol) => {
    setUser({
      ...user,
      rol: {
        ...user.rol,
        ...rol,
      },
    });
  };
  const onSubmitPI = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });
    const { uidUser, names, surname1, surname2, phone, type, status } = user;
    rqEditPI({
      variables: {
        dataUser: {
          uidUser,
          names,
          surname1,
          surname2,
          phone,
          type,
          status,
        },
      },
    });
  };

  const onSubmitRoles = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });
    const { uidUser, rol } = user;

    rqEditRol({
      variables: {
        uidUser,
        rol: {
          timeZone: rol.timeZone,
          users: {
            active: rol.users.active,
            create: rol.users.create,
            edit: rol.users.edit,
            delete: rol.users.delete,
            publish: rol.users.publish,
            show: rol.users.show,
          },
          voting: {
            active: rol.voting.active,
            create: rol.voting.create,
            edit: rol.voting.edit,
            delete: rol.voting.delete,
            publish: rol.voting.publish,
            show: rol.voting.show,
          },
          parliamentary: {
            active: rol.parliamentary.active,
            create: rol.parliamentary.create,
            edit: rol.parliamentary.edit,
            delete: rol.parliamentary.delete,
            publish: rol.parliamentary.publish,
            show: rol.parliamentary.show,
          },
          congressTopic: {
            active: rol.congressTopic.active,
            create: rol.congressTopic.create,
            edit: rol.congressTopic.edit,
            delete: rol.congressTopic.delete,
            publish: rol.congressTopic.publish,
            show: rol.congressTopic.show,
          },
          politics: {
            active: rol.politics.active,
            create: rol.politics.create,
            edit: rol.politics.edit,
            delete: rol.politics.delete,
            publish: rol.politics.publish,
            show: rol.politics.show,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (dataUser && !lUser) {
      setUser(dataUser);
      setFileImg({
        ...fileImg,
        url: dataUser.icon,
      });
    }
  }, [dataUser]);

  return (
    <Provider
      value={{
        user,
        reducer,
        onSubmitRoles,
        handleUpdateUser,
        handleUpdateUserRol,
        loading: loading || lEPI || lRol,
        message,
      }}
    >
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{language.txt.inicio}</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/usuarios">{language.txt.usuarios}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language.txt.editar}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">
              {language.txt.editarUsuario}
            </h1>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-3">
            <div className={`card  ${styles.cardProfile}`}>
              <div className={`${styles.cardProfile_img}`}>
                <div>
                  <InputFileDragAndDropImg
                    idInputFile={"idFileDragDropImgXML"}
                    dragCounter={0}
                    handleLoadFile={handleImage}
                    reset={false}
                    containerCustom={styles.containerImg}
                    ico={
                      fileImg.url !== "-" && fileImg.url !== ""
                        ? fileImg.url
                        : REACT_APP_URL_IMG_DEFAULT
                    }
                    language={language}
                  ></InputFileDragAndDropImg>
                  <MessageAction message={msnImg} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <Accordion name="accordion-user">
                <PersonalInformation
                  {...props}
                  user={user}
                  handleUpdateUser={handleUpdateUser}
                  nameParent={"accordion-user"}
                  onSubmitPI={onSubmitPI}
                  loading={loading || lEPI}
                  message={message}
                  textBtn={language.txt.actualizar}
                />
                <ChangePassword {...props} uidUser={user.uidUser} />
                <Roles
                  {...props}
                  nameParent={"accordion-user"}
                  textBtn={language.txt.actualizar}
                />
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default withContainer(EditUser)({});
