import React from "react";

import withContainer from "../withContainer";
import UploadAccesory from "../../components/inputs/UploadAccesory";

import { Context } from "./Context";
import useGetParliamentarians from "../../Hooks/useGetParliamentarians";

const { Provider } = Context;

const Parliamentary_ = (props) => {
  const { language } = props;

  const {
    parliamentarians,
    setParliamentarians,
    loading: lPar,
  } = useGetParliamentarians();

  return (
    <Provider
      value={{
        parliamentarians,
        setParliamentarians,
        loading: lPar,
      }}
    >
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{language.txt.inicio}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language.txt.InfoEstParlamentaria}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">
              {language.txt.InfoEstParlamentaria}
            </h1>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-md-12">
            <div>
              <ul className="nav nav-tabs" id="tabParliamentary" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="chambers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#chambers"
                    type="button"
                    role="tab"
                    aria-controls="chambers"
                    aria-selected="true"
                  >
                    {language.txt.estParlamentaria}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="accessories-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#accessories"
                    type="button"
                    role="tab"
                    aria-controls="accessories"
                    aria-selected="true"
                  >
                    {language.txt.complementos}
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="TabContent">
                <div
                  className="tab-pane fade show active"
                  id="chambers"
                  role="tabpanel"
                  aria-labelledby="chambers-tab"
                >
                  <div className="row bg-white p-4">
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.histVoting}
                        typeFile={"application/pdf"}
                        accessoryType={"CITIZENVOTINGH"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.dePuDofLe}
                        typeFile={"application/pdf"}
                        accessoryType={"DOFLEGISLATURE"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.dicDeTiDi}
                        typeFile={"application/pdf"}
                        accessoryType={"DICTUM"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.DePuDofPro}
                        typeFile={"application/pdf"}
                        accessoryType={"DOFPROPONENT"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.MoMaLePro}
                        typeFile={"application/pdf"}
                        accessoryType={"LEGALFRAMEWORK"}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="accessories"
                  role="tabpanel"
                  aria-labelledby="accessories-tab"
                >
                  <div className="row bg-white p-4">
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.funcionesEstructura}
                        typeFile={"application/pdf"}
                        accessoryType={"FUNCTIONSANDSTRUCTURE"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.procesoLegislativo}
                        typeFile={"application/pdf"}
                        accessoryType={"LEGISLATIVEPROCESS"}
                      />
                    </div>
                  </div>
                  <div className="row bg-white p-4">
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama1}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM1"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama2}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM2"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama3}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM3"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama4}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM4"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama5}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM5"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama6}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM6"}
                      />
                    </div>
                    <div className="col-md-4 p-2">
                      <UploadAccesory
                        {...props}
                        title={language.txt.diagrama7}
                        typeFile={"application/pdf"}
                        accessoryType={"DIAGRAM7"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export const Parliamentary = withContainer(Parliamentary_)({});
