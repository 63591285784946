import React, { useState, useEffect } from "react";
import parsePhoneNumber from "libphonenumber-js";

import { Input } from "../../../components/inputs/Inputs";
import { AccordionItem } from "../../../components/accordion/Accordion";
import { isAlphaAccents, isValidField } from "../../../utils/validations";
import useEditUser from "../../../Hooks/useEditUser";
import { MessageAction } from "../../../components/messages/Messages";

const MyData = (props) => {
  const { language, userLoggedIn } = props;
  const [user, setUser] = useState(userLoggedIn);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });

  const { editUser } = useEditUser({
    onComplete: (response) => {
      setMessage({
        message: response.message,
        code: response.code,
      });
      if (response.code === 200) {
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
        }, 5000);
      }
    },
  });

  const handleUpdateUser = (e) => {
    const { value, name } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (
      isAlphaAccents(user.names).valid &&
      isAlphaAccents(user.surname1).valid &&
      isAlphaAccents(user.surname2).valid &&
      isValidPhone
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });
    const { names, surname1, surname2, phone } = user;
    editUser({
      uidUser: userLoggedIn.uidUser,
      names,
      surname1,
      surname2,
      phone,
    });
  };

  useEffect(() => {
    if (user) {
      const phoneNumber = parsePhoneNumber(user.phone, "MX");
      if (phoneNumber) {
        setIsValidPhone(phoneNumber.isValid());
        setUser({
          ...user,
          phone: phoneNumber.formatNational(),
        });
      }
    }
  }, []);

  return (
    <AccordionItem
      title={language.txt.misDatos}
      name={"data-user"}
      nameParent={"accordion-user"}
      expanded={true}
      icon={"bi-person-lines-fill"}
    >
      <form onSubmit={onSubmit} className="needs-validation">
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name="names"
              type="text"
              label={language.txt.nombres}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.names).message}
              value={user.names}
              isValid={isValidField(
                user.names,
                isAlphaAccents(user.names).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
          <div className="col-md-6">
            <Input
              name="surname1"
              type="text"
              label={language.txt.apellido1}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.surname1).message}
              value={user.surname1}
              isValid={isValidField(
                user.surname1,
                isAlphaAccents(user.surname1).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name="surname2"
              type="text"
              label={language.txt.apellido2}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.surname2).message}
              value={user.surname2}
              isValid={isValidField(
                user.surname2,
                isAlphaAccents(user.surname2).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
          <div className="col-md-6">
            <Input
              name="phone"
              type="text"
              label={language.txt.telefono}
              icon="bi-person-fill"
              textHelper={language.txt.msnEPhone}
              value={user.phone}
              isValid={isValidPhone}
              onChange={(e) => {
                const { value } = e.target;
                let _phone = "";
                const phoneNumber = parsePhoneNumber(value, "MX");
                if (phoneNumber) {
                  setIsValidPhone(phoneNumber.isValid());
                  _phone = phoneNumber.formatNational();
                } else {
                  _phone = value;
                }
                setUser({
                  ...user,
                  phone: _phone,
                });
              }}
            />
          </div>
        </div>

        <div className="card-body">
          <hr className="dropdown-divider text-muted" />
          <MessageAction message={message} />
          <button
            type="submit"
            className="btn btn-danger my-3"
            disabled={!validateForm()}
          >
            {language.txt.actualizar}
          </button>
        </div>
      </form>
    </AccordionItem>
  );
};

export default MyData;
