import React from "react";

export const Context = React.createContext({
  loading: false,
  showFormT: false,
  message: "",
  topic: "",
  uidTopic: "",
  topics: [],
  setUidTopic: () => {},
  setTopic: () => {},
  type: "UNKNOWN",
  action: 0,
  setType: () => {},
  addTopic: () => {},
  editTopic: () => {},
  handleAddTopic: () => {},
  handleEditTopic: () => {},
  handleChangeStatusTopic: () => {},
  changeStatusTopic: () => {},
  handleAddTopicChild: () => {},
  handleEditTopicChild: () => {},
  handleChangeStatusTopicChild: () => {},
  handleRestart: () => {},
});
