import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { congressTopicsModel } from "../apollo/ContextApp";
import { GET_CONGRESS_TOPICS } from "./querys";

const useLazyGetCongressTopics = () => {
  const [topics, setTopics] = useState(congressTopicsModel);
  const [rqTopics, { loading: lTopics }] = useLazyQuery(GET_CONGRESS_TOPICS, {
    onCompleted: (data) => {
      setTopics(data.getCongressTopics);
    },
    fetchPolicy: "no-cache",
  });

  return { rqTopics, topics, loading: lTopics, setTopics };
};

export default useLazyGetCongressTopics;
