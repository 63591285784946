import { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { SEARCH_TOPICS } from "./querys";

const useLazySearchTopics = () => {
  const [topics, setTopics] = useState({ topics: [], count: 0 });
  const [rqTopics, { loading: lTopics }] = useLazyQuery(SEARCH_TOPICS, {
    onCompleted: (data) => {
      setTopics(data.searchTopics);
    },
  });

  return { rqTopics, topics, loading: lTopics };
};

export default useLazySearchTopics;
