import { gql } from "@apollo/client";

export const CHANGE_AVATAR = gql`
  mutation ChangeAvatar($uidUser: String!, $icon: String!) {
    changeAvatar(uidUser: $uidUser, icon: $icon) {
      code
      message
    }
  }
`;
