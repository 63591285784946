import React, { useState, useContext, useEffect } from "react";

import { Accordion } from "../../components/accordion/Accordion";
import CTopic from "./Topic";
import { Context } from "./Context";
import { InputCheck } from "../../components/inputs/Inputs";
import useLazyGetCongressTopics from "../../Hooks/useLazyGetCongressTopics";

const nameParent = "accordion-congress";

const ListTopic = (props) => {
  const { language } = props;
  const [active, setActive] = useState(true);
  const [inactive, setInactive] = useState(true);
  const [status, setStatus] = useState([0, 1]);

  const { loading, topics } = useContext(Context);
  const { rqTopics, loading: lTopics } = useLazyGetCongressTopics();

  useEffect(() => {
    let _status = [];
    if (inactive) {
      _status.push(0);
    }
    if (active) {
      _status.push(1);
    }
    setStatus(_status);
  }, [active, inactive]);

  return (
    <div className="bg-white p-2 py-5">
      <div className="d-flex justify-content-start align-items-center gap-3">
        <InputCheck
          name="active"
          checked={active}
          label={language.txt.activos}
          onChange={(e) => {
            const { checked } = e.target;
            setActive(checked);
          }}
        />
        <InputCheck
          name="inactive"
          checked={inactive}
          label={language.txt.inactivos}
          onChange={(e) => {
            const { checked } = e.target;
            setInactive(checked);
          }}
        />
      </div>
      <div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Accordion name={nameParent}>
            {topics.topics.map((topic, index) => (
              <CTopic
                {...props}
                key={index}
                topic={topic}
                nameParent={nameParent}
              />
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default ListTopic;
