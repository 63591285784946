import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import styles from "./styles.module.scss";
import withContainer from "../../withContainer";
import InputFileDragAndDropImg from "../../../components/inputs/InputFileDragAndDropImg";
import { Input } from "../../../components/inputs/Inputs";
import { MessageAction } from "../../../components/messages/Messages";
import { Accordion } from "../../../components/accordion/Accordion";
import PersonalInformation from "./PersonalInformation";
import useUpload from "../../../Hooks/useUpload";
import { userModel } from "../../../apollo/ContextApp";
import { CREATE_USER } from "./mutations";

const { REACT_APP_URL_IMG_DEFAULT } = process.env;

const CreateUser = (props) => {
  const { language, history, userLoggedIn } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });
  const [user, setUser] = useState(userModel);
  const [msnImg, setMsnImg] = useState({ message: "", code: 200 });
  const [fileImg, setFileImg] = useState({
    file: undefined,
    response: { code: 0, message: "" },
    url: "",
    name: "",
  });

  const { upload, loading: lUpload } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setFileImg({
          ...fileImg,
          url: uploadFile.url,
        });
        setUser({
          ...user,
          icon: uploadFile.url,
        });
      }
      setMsnImg({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnImg({ message: "", code: 200 });
      }, 3000);
    },
  });

  const [rqCreateU, { loading: lCreateU }] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      setLoading(false);
      setMessage({
        ...createUser.response,
      });
      if (createUser.response.code == 200) {
        setMessage({
          code: 200,
          message: language.txt.msCreacionUsuarioOk,
        });

        setTimeout(() => {
          history.push("/usuarios/editar/" + createUser.uidUser);
        }, 2000);
      }
    },
  });

  const handleImage = (files) => {
    setMsnImg({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
      setFileImg({
        file: files[0],
        response: { code: 0, message: "" },
        url: "-",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setFileImg({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnImg({ message: language.txt.errorformatoArchivoImg, code: 401 });
    }
  };

  const handleUpdateUser = (e) => {
    const { value, name } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const onSubmitPI = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });
    const {
      email,
      names,
      surname1,
      surname2,
      icon,
      phone,
      type,
      status,
    } = user;
    rqCreateU({
      variables: {
        dataUser: {
          email,
          names,
          surname1,
          surname2,
          icon,
          phone,
          type,
          status,
        },
      },
    });
  };

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !userLoggedIn.rol.users.create) {
      history.push("/");
    }
  }, [history, userLoggedIn]);

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">{language.txt.inicio}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/usuarios">{language.txt.usuarios}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {language.txt.crear}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row my-5">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">
              {language.txt.agregarUsuario}
            </h1>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-3">
            <div className={`card  ${styles.cardProfile}`}>
              <div className={`${styles.cardProfile_img}`}>
                <div>
                  <InputFileDragAndDropImg
                    idInputFile={"idFileDragDropImgXML"}
                    dragCounter={0}
                    handleLoadFile={handleImage}
                    reset={false}
                    containerCustom={styles.containerImg}
                    ico={
                      fileImg.url !== "-" && fileImg.url !== ""
                        ? fileImg.url
                        : REACT_APP_URL_IMG_DEFAULT
                    }
                    language={language}
                  ></InputFileDragAndDropImg>
                  <MessageAction message={msnImg} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <Accordion name="accordion-user">
                <PersonalInformation
                  {...props}
                  user={user}
                  handleUpdateUser={handleUpdateUser}
                  nameParent={"accordion-user"}
                  onSubmitPI={onSubmitPI}
                  loading={loading || lCreateU}
                  message={message}
                  textBtn={language.txt.crear}
                />
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withContainer(CreateUser)({});
