import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import reportWebVitals from "./reportWebVitals";

//import $ from "jquery";
//import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./styles/index.scss";

import client from "./apollo/Client";
import { MainConfig } from "./pages/Routes";

function App() {
  return (
    <ApolloProvider client={client}>
      <MainConfig />
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
