import { gql } from "@apollo/client";

export const FIND_CURRENT_USER = gql`
  query FindCurrentUser {
    findCurrentUser {
      response {
        code
        message
      }
      uidUser
      names
      surname1
      surname2
      icon
      email
      status
      rol {
        timeZone
        users {
          active
          create
          edit
          delete
          show
        }
        voting {
          active
          create
          edit
          delete
          publish
          show
        }
        parliamentary {
          active
          create
          edit
          delete
          show
        }
        congressTopic {
          active
          create
          edit
          delete
          show
        }
        politics {
          active
          create
          edit
          delete
          show
        }
      }
      type
      phone
      creatorUser {
        uidUser
        email
      }
      parentUser {
        uidUser
        email
      }
      creationDate
    }
  }
`;
