import React, { useContext } from "react";

import { MessageAction } from "../../components/messages/Messages";
import { Context } from "./Context";

const FormTopicOK = (props) => {
  const { language } = props;

  const {
    loading,
    message,
    text,
    action,
    changeStatusTopic,
    handleRestart,
  } = useContext(Context);

  const handleOk = (e) => {
    if (action == 2 || action == 5) {
      changeStatusTopic();
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h3>{language.txt.msnEdicion}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <br />
          <MessageAction message={message} />
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={loading}
            onClick={handleOk}
          >
            {language.txt.actualizar}
          </button>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={loading}
            onClick={() => {
              handleRestart();
            }}
          >
            {language.txt.cancelar}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormTopicOK;
