import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { FIND_CURRENT_USER } from "./querys";
import { userModel } from "../apollo/ContextApp";

import Langueage from "../language/Langueage";

const withConfig = (Component) => (props) => {
  const { data: userL, refetch } = useQuery(FIND_CURRENT_USER);
  const [userLoggedIn, setUserLoggedIn] = useState(userModel);

  useEffect(() => {
    if (userL && userL.findCurrentUser.response) {
      //console.log(window.location.pathname);
      if (userL.findCurrentUser.response.code === 200) {
        let user = {
          ...userModel,
          ...userL.findCurrentUser,
        };

        if (!user.rol.politics) {
          user.rol = {
            ...user.rol,
            politics: { ...userModel.rol.politics },
          };
        }

        console.log(user);
        setUserLoggedIn(user);
        localStorage.setItem("__uidu", userL.findCurrentUser.uidUser);
      } else if (userL.findCurrentUser.response.code === 401) {
        //console.log(window.location.pathname);
        if (window.location.pathname !== "/login") {
          window.location.href = window.location.pathname;
        }
      }
    }
  }, [userL]);

  return (
    <Component
      {...props}
      userLoggedIn={userLoggedIn}
      language={new Langueage(userLoggedIn.language)}
      refetch={refetch}
    />
  );
};

export default withConfig;
