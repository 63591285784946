import React, { useContext } from "react";

import { AccordionItem } from "../../components/accordion/Accordion";
import { Context } from "./Context";

const CTopic = (props) => {
  const { userLoggedIn, language, topic, nameParent, showFormT } = props;

  const {
    loading,
    handleEditTopic,
    handleChangeStatusTopic,
    handleAddTopicChild,
    handleEditTopicChild,
    handleChangeStatusTopicChild,
  } = useContext(Context);

  const handleBtn1 = () => {
    handleEditTopic(topic);
  };

  const handleBtn2 = () => {
    if (topic.status == 0) {
      handleChangeStatusTopic(topic, 1);
    } else {
      handleChangeStatusTopic(topic, 0);
    }
  };

  return (
    <AccordionItem
      title={topic.topic}
      subTitle={""}
      name={topic.uidCongressTopic}
      nameParent={nameParent}
      expanded={false}
      icon={"bi bi-caret-right-fill"}
      iconBtn1={"bi bi-pencil"}
      handleBtn1={handleBtn1}
      disabledBtn1={showFormT}
      iconBtn2={
        topic.status == 0
          ? "bi bi-toggle-off text-danger"
          : "bi bi-toggle-on text-success"
      }
      handleBtn2={handleBtn2}
      disabledBtn2={showFormT}
    >
      {userLoggedIn.rol.congressTopic.create && !loading && !showFormT ? (
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="fw-bold btn btn-danger btn-sm"
            onClick={() => {
              handleAddTopicChild(topic);
            }}
          >
            <i className="bi-plus-circle fw-bold me-2"></i>
            <span>{language.txt.nuevoSubTema}</span>
          </button>
        </div>
      ) : (
        ""
      )}
      <h3>{language.txt.subtemas}</h3>
      <hr className="dropdown-divider text-muted" />
      <ul className="list-group list-group-flush my-3">
        {topic.topics.map((t) => (
          <li
            key={t.uidCongressTopic}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>{t.topic}</span>
            <div>
              <button
                className="btn"
                onClick={() => {
                  handleEditTopicChild(t);
                }}
                disabled={loading}
              >
                <i className={"bi bi-pencil"}></i>
              </button>
              <button
                className="btn"
                onClick={() => {
                  if (t.status == 0) {
                    handleChangeStatusTopicChild(t, 1);
                  } else {
                    handleChangeStatusTopicChild(t, 0);
                  }
                }}
                disabled={loading}
              >
                <i
                  className={
                    t.status == 0
                      ? "bi bi-toggle-off text-danger"
                      : "bi bi-toggle-on text-success"
                  }
                ></i>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </AccordionItem>
  );
};

export default CTopic;
