import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import withConfig from "./withConfig";

//Login
import Login from "./login";

//Analytics
import P404 from "./main/P404";

//Users
import Profile from "./users/profile";
import Users from "./users/users";
import CreateUser from "./users/users/CreateUser";
import EditUser from "./users/users/EditUser";

//Votaciones
import Votaciones from "./voting";
import CreateVote from "./voting/CreateVote";
import EditVote from "./voting/EditVote";

//parlamentaria
import { Parliamentary } from "./parliamentary";

//Temas del congreso
import CongressTopic from "./congressTopic";

//Políticas
import Politics from "./politics";

export const Routes = (props) => {
  const { userLoggedIn, language } = props;

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={() => <Login {...props} />} />
        <Route
          exact
          path="/"
          component={() => (
            <Votaciones userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/profile"
          component={() => (
            <Profile userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/votaciones"
          component={() => (
            <Votaciones userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/votaciones/crear"
          component={() => (
            <CreateVote userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/votaciones/editar/:id"
          component={() => (
            <EditVote userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/usuarios"
          component={() => (
            <Users userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/usuarios/crear"
          component={() => (
            <CreateUser userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/usuarios/editar/:id"
          component={() => (
            <EditUser userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/parlamentaria"
          component={() => (
            <Parliamentary userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/temas-congreso"
          component={() => (
            <CongressTopic userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          exact
          path="/politicas"
          component={() => (
            <Politics userLoggedIn={userLoggedIn} language={language} />
          )}
        />
        <Route
          component={() => (
            <P404 userLoggedIn={userLoggedIn} language={language} />
          )}
        />
      </Switch>
    </Router>
  );
};

const MainConfig = withConfig(Routes);
export { MainConfig };
