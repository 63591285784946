import React, { Component } from "react";
import styles from "./InputFileDragAndDropImg.module.scss";

class InputFileDragAndDropImg extends Component {
  state = {
    drag: false,
    dragCounter: this.props.dragCounter ? this.props.dragCounter : 0,
  };
  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ ...this.state, dragCounter: 1 });
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ ...this.state, drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.state.dragCounter--;
    if (this.state.dragCounter === 0) {
      this.setState({ ...this.state, drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleLoadFile(e.dataTransfer.files);
      e.dataTransfer.clearData();
      //this.state.dragCounter = 0;
    }
  };
  handleLoadFileInput = (e) => {
    e.preventDefault();
    const { files } = e.target;
    this.setState({ ...this.state, dragCounter: 1 });
    this.props.handleLoadFile(files);
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
    this.setState({
      ...this.state,
      dragCounter: this.props.dragCounter,
    });
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    const { reset } = this.props;
    return (
      <div
        id="field-drag-drop"
        className={`${styles.container} ${this.props.containerCustom}`}
        ref={this.dropRef}
      >
        <div
          className={`${styles.containerInput} ${this.props.containerInputCustom}`}
        >
          <React.Fragment>
            <div
              className={`${styles.containerInputFile} ${this.props.containerInputFileCustom}`}
            >
              <input
                type="file"
                name="file"
                id={this.props.idInputFile}
                className={`${styles.inputFile}`}
                onChange={this.handleLoadFileInput}
              />
              <img alt="Cargando..." src={this.props.ico} />
            </div>

            {this.state.dragCounter == 0 || reset ? (
              <p className="text-center my-4">
                {this.props.language.txt.msnSeleccioArchivo}
              </p>
            ) : (
              ""
            )}
          </React.Fragment>

          {this.state.drag ? (
            <div>
              <div>
                <div>Suelta archivo aquí</div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}
export default InputFileDragAndDropImg;
