import { useState } from "react";
import { useMutation } from "@apollo/client";

import { CHANGE_PASSWORD } from "./mutations";

const useChangePassword = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rqChange, { loading: ll }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: ({ changePassword }) => {
      setLoading(false);
      if (onComplete) {
        onComplete(changePassword);
      }
    },
  });

  const changePass = ({ uidUser, password }) => {
    try {
      setLoading(true);
      rqChange({
        variables: {
          uidUser,
          password,
        },
      });
    } catch (x) {
      console.log(x);
    }
  };

  return { loading: loading || ll, changePass };
};

export default useChangePassword;
