import React from "react";

import styles from "./styles.module.scss";

export const MessageAction = (props) => {
  const { message } = props;
  return (
    <>
      {message.message !== "" ? (
        <div
          className={`p-3 text-center ${
            message.code === 200 ? "bg-light" : "bg-danger text-white"
          }`}
          style={{ zIndex: 1000, width: "100%" }}
        >
          {message.message}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const LoadingScreen = (props) => {
  const { userLoggedIn, loading } = props;

  return (
    <div
      className={`${styles.containerWait} ${
        userLoggedIn.uidUser === "" || loading ? "" : styles.containerWait_exit
      }`}
    >
      <div className="center-abs">
        <div className="d-flex justify-content-center align-items-center">
          <div
            className={`spinner-grow text-info ${styles.containerWait_size}`}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WinActions = (props) => {
  const { id, title, children } = props;
  return (
    <div
      className="modal fade show"
      id={id}
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
