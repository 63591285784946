import { gql } from "@apollo/client";

export const ADD_ACCESSORY = gql`
  mutation AddAccessory($accessory: InsAccessory!) {
    addAccessory(accessory: $accessory) {
      response {
        code
        message
      }
      uidAccessory
      type
      url
      name
    }
  }
`;
