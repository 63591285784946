import { gql } from "@apollo/client";

export const GET_ALL_VOTINGS_BY_TOKEN = gql`
  query GetAllTopics($limit: Int!, $offset: Int!) {
    getAllTopics(limit: $limit, offset: $offset) {
      response {
        code
        message
      }
      count
      topics {
        uidVoting
        name
        topic
        image {
          url
          name
        }
        briefDescription
        broadDescription {
          url
          name
        }
        extensiveDescription {
          url
          name
        }
        duration
        creationDate
        user {
          uidUser
          names
          surname1
          surname2
          icon
          email
          phone
        }
        status
        approvalStatus
      }
    }
  }
`;

export const SEARCH_TOPICS = gql`
  query SearchTopics($search: SearchTopic!) {
    searchTopics(search: $search) {
      response {
        code
        message
      }
      count
      topics {
        uidVoting
        name
        topic
        image {
          url
          name
        }
        briefDescription
        broadDescription {
          url
          name
        }
        extensiveDescription {
          url
          name
        }
        duration
        creationDate
        user {
          uidUser
          names
          surname1
          surname2
          icon
          email
          phone
        }
        status
        approvalStatus
        turn
        nextProcess
      }
    }
  }
`;

export const GET_TOPIC = gql`
  query GetTopic($uidUser: String!, $uidVoting: String!) {
    getTopic(uidUser: $uidUser, uidVoting: $uidVoting) {
      response {
        code
        message
      }
      uidVoting
      name
      topic
      image {
        url
        name
      }
      briefDescription
      broadDescription {
        url
        name
      }
      extensiveDescription {
        url
        name
      }
      duration
      creationDate
      user {
        uidUser
        names
        surname1
        surname2
        icon
        email
        phone
      }
      status
      approvalStatus
      turn
      nextProcess
    }
  }
`;

export const SEARCH_USER = gql`
  query SearchUser($search: SearchUser!) {
    searchUser(search: $search) {
      response {
        code
        message
      }
      count
      users {
        uidUser
        names
        surname1
        surname2
        icon
        email
        status
        rol {
          timeZone
          users {
            active
            create
            edit
            delete
          }
          voting {
            active
            create
            edit
            delete
            publish
          }
          parliamentary {
            active
            create
            edit
            delete
          }
        }
        type
        phone
        creatorUser {
          uidUser
          email
        }
        parentUser {
          uidUser
          email
        }
        creationDate
      }
    }
  }
`;

export const FIND_USER_UID = gql`
  query FindUserByUId($uidUser: String!) {
    findUserByUId(uidUser: $uidUser) {
      response {
        code
        message
      }
      uidUser
      names
      surname1
      surname2
      icon
      email
      status
      rol {
        timeZone
        users {
          active
          create
          edit
          delete
          show
        }
        voting {
          active
          create
          edit
          delete
          publish
          show
        }
        parliamentary {
          active
          create
          edit
          delete
          show
        }
        congressTopic {
          active
          create
          edit
          delete
          show
        }
        politics {
          active
          create
          edit
          delete
          show
        }
      }
      type
      phone
      creatorUser {
        uidUser
        email
      }
      parentUser {
        uidUser
        email
      }
      creationDate
    }
  }
`;

export const GET_DATA_CHAMBER = gql`
  query GetDataChamber($dataChamber: InGetChamber!) {
    getDataChamber(dataChamber: $dataChamber) {
      response {
        code
        message
      }
      uidVoting
      type
      inFavor
      against
      abstention
      total
      approvalStatus
    }
  }
`;

export const GET_PARLIAMENTARIANS = gql`
  query GetParliamentarians {
    getParliamentarians {
      response {
        code
        message
      }
      count
      parliamentarians {
        uidParliamentary
        origin
        legislature
        constitutionalReforms
        legalReforms
        decrees
        total
      }
    }
  }
`;

export const GET_ACCESSORIES = gql`
  query GetAccessories {
    getAccessories {
      response {
        code
        message
      }
      accessories {
        uidAccessory
        type
        url
        name
      }
    }
  }
`;

export const GET_CONGRESS_TOPICS = gql`
  query GetCongressTopics($status: [Int]) {
    getCongressTopics(status: $status) {
      response {
        code
        message
      }
      topics {
        uidCongressTopic
        topic
        type
        status
        topics {
          uidCongressTopic
          topic
          type
          status
        }
      }
    }
  }
`;

export const GET_CONGRESS_MAILBOX = gql`
  query GetCongressMailbox {
    getCongressMailbox {
      response {
        code
        message
      }
      topics {
        topic
        topicChild
        total
      }
    }
  }
`;

export const GET_POLITICS = gql`
  query GetPolitics {
    getPolitics {
      response {
        code
        message
      }
      politics {
        id
        type
        politic
        version
      }
    }
  }
`;
