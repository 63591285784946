import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { FIND_USER_UID } from "./querys";
import { userModel } from "../apollo/ContextApp";

const useFindUserUid = ({ uidUser }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(userModel);
  const { data: dUser, loading: lUser } = useQuery(FIND_USER_UID, {
    variables: {
      uidUser,
    },
  });
  useEffect(() => {
    if (dUser && dUser.findUserByUId) {
      if (dUser.findUserByUId.response.code === 200) {
        let dataUser = {
          ...dUser.findUserByUId,
        };

        if (!dUser.findUserByUId.rol.congressTopic) {
          dataUser = {
            ...dataUser,
            rol: {
              ...dataUser.rol,
              congressTopic: userModel.rol.congressTopic,
            },
          };
        }

        if (!dUser.findUserByUId.rol.politics) {
          dataUser = {
            ...dataUser,
            rol: {
              ...dataUser.rol,
              politics: userModel.rol.politics,
            },
          };
        }

        setUser(dataUser);
        setLoading(false);
      }
    }
  }, [dUser]);
  return { user, loading: loading || lUser };
};

export default useFindUserUid;
