import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";

import useLazySearchUsers from "../../../Hooks/useLazySearchUsers";
import { Input, InputCheck } from "../../../components/inputs/Inputs";
import { isAlphaAccents, isValidField } from "../../../utils/validations";
import useUI from "../../../Hooks/useUI";

const ListUsers = (props) => {
  const { language, userLoggedIn, updateCountUser } = props;
  const { ui, updateUI, loading: lUi } = useUI();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dataSet, setDataSet] = useState([]);
  const [search, setSearch] = useState("");
  const tableRef = useRef();
  const refSearch = useRef(null);
  const { rqUsers, users, loading: lUsers } = useLazySearchUsers();

  const columns = [
    { title: "ID", field: "uidUser", hidden: true },
    {
      title: language.txt.imagen,
      field: "icon",
      render: (rowData) => (
        <img style={{ height: 36, borderRadius: "50%" }} src={rowData.icon} />
      ),
    },
    {
      title: language.txt.nombre,
      field: "names",
    },
    {
      title: language.txt.email,
      field: "email",
    },
    {
      title: language.txt.telefono,
      field: "phone",
    },
    {
      title: language.txt.status,
      field: "status",
      render: (rowData) =>
        rowData.status === "INACTIVE"
          ? language.txt.inactiva
          : language.txt.activo,
    },
    {
      title: language.txt.tipo,
      field: "type",
      render: (rowData) =>
        rowData.type === "UNKNOWN"
          ? language.txt.desconocido
          : rowData.type === "ADMIN"
          ? language.txt.administrador
          : rowData.type === "EDITOR"
          ? language.txt.editor
          : rowData.type === "GENERALVOTER"
          ? language.txt.votantegeneral
          : rowData.type === "VOTER"
          ? language.txt.votante
          : language.txt.desconocido,
    },
  ];

  const handelFilter = () => {
    let type = [];
    if (ui.filterUsers.admin) {
      type.push(1);
    }
    if (ui.filterUsers.editor) {
      type.push(2);
    }
    if (ui.filterUsers.generalVoter) {
      type.push(3);
    }
    if (ui.filterUsers.voter) {
      type.push(4);
    }

    let status = [];
    if (ui.filterUsers.inactive) {
      status.push(0);
    }
    if (ui.filterUsers.active) {
      status.push(1);
    }
    return {
      type,
      status,
    };
  };

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !lUsers && !lUi) {
      setTimeout(() => {
        if (refSearch.current) {
          if (search === refSearch.current.value) {
            const states = handelFilter();
            rqUsers({
              variables: {
                search: {
                  search,
                  uidUser: userLoggedIn.uidUser,
                  limit,
                  offset,
                  type: states.type,
                  status: states.status,
                },
              },
            });
          }
        }
      }, 600);
    }
  }, [search]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
    const states = handelFilter();
    updateCountUser({
      search,
      uidUser: userLoggedIn.uidUser,
      limit: users.count,
      offset,
      type: states.type,
      status: states.status,
    });
  }, [users]);

  if (lUi && ui) {
    return "";
  }

  return (
    <div>
      <div className="row my-2 p-2 d-flex justify-content-center align-items-center">
        <div className="col-md-6 my-3">
          <Input
            name="search"
            label={language.txt.buscar + ", " + language.txt.msnBusqueda}
            rows={10}
            value={search}
            textHelper={isAlphaAccents(search).message}
            isValid={isValidField(search, isAlphaAccents(search).valid)}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            ref={refSearch}
          />
        </div>
        <div className="col-md-2 my-3">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-danger dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {language.txt.filtrar}
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  {language.txt.limpiar}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <span className="dropdown-item">{language.txt.status}</span>
              </li>
              <li className="px-2">
                <InputCheck
                  name="inactive"
                  checked={ui.filterUsers.inactive}
                  label={language.txt.inactiva}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        inactive: !ui.filterUsers.inactive,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="active"
                  checked={ui.filterUsers.active}
                  label={language.txt.activo}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        active: !ui.filterUsers.active,
                      },
                    });
                  }}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <span className="dropdown-item">{language.txt.tipo}</span>
              </li>
              <li className="px-2">
                <InputCheck
                  name="admin"
                  checked={ui.filterUsers.admin}
                  label={language.txt.administrador}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        admin: !ui.filterUsers.admin,
                        voter: false,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="admin"
                  checked={ui.filterUsers.editor}
                  label={language.txt.editor}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        editor: !ui.filterUsers.editor,
                        voter: false,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="generalVoter"
                  checked={ui.filterUsers.generalVoter}
                  label={language.txt.votantegeneral}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        generalVoter: !ui.filterUsers.generalVoter,
                        voter: false,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="generalVoter"
                  checked={ui.filterUsers.voter}
                  label={language.txt.votante}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filterUsers: {
                        ...ui.filterUsers,
                        voter: !ui.filterUsers.voter,
                        admin: false,
                        editor: false,
                        generalVoter: false,
                      },
                    });
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-12">
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={columns}
              options={{
                search: false,
                maxBodyHeight: "550px",
                initialPage: offset,
                pageSize: limit,
                pageSizeOptions: [
                  parseInt(limit / 2),
                  limit,
                  limit * 5,
                  limit * 10,
                ],
              }}
              data={() =>
                new Promise((resolve) => {
                  let _dataSet = [];
                  users.users.forEach((user) => {
                    dataSet.push({
                      uidUser: user.uidUser,
                      icon: user.icon,
                      names: user.names,
                      email: user.email,
                      phone: user.phone,
                      status: user.status,
                      type: user.type,
                    });
                  });
                  setDataSet(_dataSet);
                  resolve({
                    data: dataSet, // your data array
                    page: offset, // current page number
                    totalCount: users.count + limit, // total row number
                  });
                })
              }
              onChangePage={(e) => {
                const states = handelFilter();
                setOffset(e);
                rqUsers({
                  variables: {
                    search: {
                      search,
                      uidUser: userLoggedIn.uidUser,
                      limit,
                      offset: e,
                      type: states.type,
                      status: states.status,
                    },
                  },
                });
              }}
              onChangeRowsPerPage={(e) => {
                if (e !== 0) {
                  const states = handelFilter();
                  setLimit(e);
                  rqUsers({
                    variables: {
                      search: {
                        search,
                        uidUser: userLoggedIn.uidUser,
                        limit: e,
                        offset,
                        type: states.type,
                        status: states.status,
                      },
                    },
                  });
                }

                tableRef.current && tableRef.current.onQueryChange();
              }}
              onSearchChange={(e) => {
                const states = handelFilter();
                setSearch(e);
                rqUsers({
                  variables: {
                    search: {
                      search: e,
                      uidUser: userLoggedIn.uidUser,
                      limit,
                      offset,
                      type: states.type,
                      status: states.status,
                    },
                  },
                });
              }}
              isLoading={lUsers}
              actions={[
                {
                  icon: "edit",
                  tooltip: language.txt.editar,
                  title: "",
                  onClick: (event, rowData) =>
                    props.history.push("/usuarios/editar/" + rowData.uidUser),
                },
              ]}
              localization={{
                header: {
                  actions: "",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
