import React, { useEffect } from "react";

import useLazyGetCongressMailbox from "../../Hooks/useLazyGetCongressMailbox";

const Mailbox = (props) => {
  const { language } = props;

  const { rqMailbox, topics, loading } = useLazyGetCongressMailbox();

  useEffect(() => {
    rqMailbox();
  }, []);

  return (
    <div className="bg-white p-2 py-5">
      <h3>{language.txt.conteoTemasCongreso}</h3>
      <hr className="dropdown-divider text-muted" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <ul className="list-group list-group-flush my-3">
          {topics.topics.map((t, i) => (
            <li
              key={i}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex flex-column justify-content-center align-items-start">
                <div>{t.topic}</div>
                <div>{t.topicChild}</div>
              </div>
              <div className="me-3">
                <h2>
                  <span className="badge bg-success">{t.total}</span>
                </h2>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Mailbox;
