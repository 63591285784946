import { InMemoryCache } from "@apollo/client";

import { uiModel } from "./ContextApp";
import { GET_UI } from "./localQuerys";

const cache = new InMemoryCache();

//-----------UI-------------------//

let uiDefault = uiModel;
const __ui = localStorage.getItem("__ui");
if (__ui) {
  uiDefault = JSON.parse(__ui);
}

cache.writeQuery({
  query: GET_UI,
  data: {
    ui: uiDefault,
  },
});

export const updateUI = (newUI) => {
  const { ui } = cache.readQuery({ query: GET_UI });
  const _ui = {
    ...ui,
    ...newUI,
  };
  cache.writeQuery({
    query: GET_UI,
    data: {
      ui: _ui,
    },
  });
  localStorage.setItem("__ui", JSON.stringify(_ui));
};

//-----------UI-------------------//

export default cache;
