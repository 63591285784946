import { useState } from "react";
import { useMutation } from "@apollo/client";

import { UPLOAD } from "./mutations";

const useUpload = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rqUpload, { loading: ll }] = useMutation(UPLOAD, {
    onCompleted: ({ uploadFile }) => {
      setLoading(false);
      if (onComplete) {
        onComplete(uploadFile);
      }
    },
  });

  const upload = ({ file, isPublic }) => {
    try {
      setLoading(true);
      rqUpload({
        variables: {
          file,
          isPublic,
        },
      });
    } catch (x) {
      console.log(x);
    }
  };

  return { loading: loading || ll, upload };
};

export default useUpload;
