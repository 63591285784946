import { useState } from "react";
import { useMutation } from "@apollo/client";

import { LOGIN } from "./mutations";

const useAuth = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rqLogin, { loading: ll }] = useMutation(LOGIN, {
    onCompleted: ({ login }) => {
      setLoading(false);
      if (onComplete) {
        onComplete(login);
      }
    },
  });

  const login = ({ email, password, refresh }) => {
    try {
      setLoading(true);
      rqLogin({
        variables: {
          email,
          password,
          refresh,
        },
      });
    } catch (x) {
      console.log(x);
    }
  };
  return { loading: loading || ll, login };
};

export default useAuth;
