import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import styles from "./styles.module.scss";
import withContainer from "../../withContainer";
import InputFileDragAndDropImg from "../../../components/inputs/InputFileDragAndDropImg";
import { Accordion } from "../../../components/accordion/Accordion";
import { MessageAction } from "../../../components/messages/Messages";

import ChangePassword from "./ChangePassword";
import MyData from "./MyData";
import useUpload from "../../../Hooks/useUpload";
import { CHANGE_AVATAR } from "./mutations";

const { REACT_APP_URL_IMG } = process.env;

const Profile = (props) => {
  const { userLoggedIn, language } = props;
  const [msnImg, setMsnImg] = useState({ message: "", code: 200 });
  const [fileImg, setFileImg] = useState({
    file: undefined,
    response: { code: 0, message: "" },
    url: userLoggedIn.icon,
    name: "",
  });

  const [rqChangeAvatar, { loading: lCA }] = useMutation(CHANGE_AVATAR, {
    onCompleted: ({ changeAvatar }) => {
      setMsnImg({ ...changeAvatar });
    },
  });

  const { upload, loading: lUpload } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setFileImg({
          ...fileImg,
          url: uploadFile.url,
        });
        rqChangeAvatar({
          variables: {
            icon: uploadFile.url,
            uidUser: userLoggedIn.uidUser,
          },
        });
      }
      setMsnImg({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnImg({ message: "", code: 200 });
      }, 3000);
    },
  });

  const handleImage = (files) => {
    setMsnImg({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
      setFileImg({
        file: files[0],
        response: { code: 0, message: "" },
        url: "-",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setFileImg({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnImg({ message: language.txt.errorformatoArchivoImg, code: 401 });
    }
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">{language.txt.inicio}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {language.txt.miperfil}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-md-12 d-flex justify-content-between align-items-center">
          <h1 className="fw-bold text-dark h2">{language.txt.miperfil}</h1>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-3">
          <div className={`card  ${styles.cardProfile}`}>
            <div className={`${styles.cardProfile_img}`}>
              <div>
                <InputFileDragAndDropImg
                  idInputFile={"idFileDragDropImgXML"}
                  dragCounter={0}
                  handleLoadFile={handleImage}
                  reset={false}
                  containerCustom={styles.containerImg}
                  ico={
                    fileImg.url !== "-"
                      ? fileImg.url
                      : REACT_APP_URL_IMG + "/camera.png"
                  }
                  language={language}
                ></InputFileDragAndDropImg>
                <MessageAction message={msnImg} />
              </div>
            </div>

            <div className="card-body">
              <h5 className="card-title">
                {userLoggedIn.names + " " + userLoggedIn.surname1}
              </h5>
              <hr className="dropdown-divider text-muted" />
              <p className="">{userLoggedIn.email}</p>
              <hr className="dropdown-divider text-muted" />
              <p className="">{userLoggedIn.type}</p>
              <hr className="dropdown-divider text-muted" />
              <p className="">{userLoggedIn.status}</p>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <Accordion name="accordion-user">
              <MyData {...props} />
              <ChangePassword {...props} uidUser={userLoggedIn.uidUser} />
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withContainer(Profile)({});
