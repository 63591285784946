import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $refresh: Boolean!) {
    login(login: { email: $email, password: $password, refresh: $refresh }) {
      code
      message
      token
      uidToken
    }
  }
`;

export const EDIT_USER = gql`
  mutation(
    $uidUser: String!
    $names: String!
    $surname1: String!
    $surname2: String
    $phone: String!
  ) {
    editUser(
      dataUser: {
        uidUser: $uidUser
        names: $names
        surname1: $surname1
        surname2: $surname2
        phone: $phone
      }
    ) {
      code
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($uidUser: String!, $password: String!) {
    changePassword(uidUser: $uidUser, password: $password) {
      code
      message
    }
  }
`;

export const UPLOAD = gql`
  mutation Uploads($file: Upload!, $isPublic: Boolean!) {
    uploadFile(file: $file, isPublic: $isPublic) {
      response {
        code
        message
      }
      url
      file {
        filename
        encoding
        mimetype
      }
    }
  }
`;

export const ADD_TOPIC = gql`
  mutation CreateTopic($topic: CreateTopic!) {
    createTopic(topic: $topic) {
      response {
        code
        message
      }
      uidVoting
    }
  }
`;
