import { useState } from "react";
import { useMutation } from "@apollo/client";

import { EDIT_USER } from "./mutations";

const useEditUser = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rqEdit, { loading: ll }] = useMutation(EDIT_USER, {
    onCompleted: ({ editUser }) => {
      setLoading(false);
      if (onComplete) {
        onComplete(editUser);
      }
    },
  });

  const editUser = ({ uidUser, names, surname1, surname2, phone }) => {
    try {
      setLoading(true);
      rqEdit({
        variables: {
          uidUser,
          names,
          surname1,
          surname2,
          phone,
        },
      });
    } catch (x) {
      console.log(x);
    }
  };
  return { loading: loading || ll, editUser };
};

export default useEditUser;
