import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { dataChamberModel } from "../apollo/ContextApp";
import { GET_DATA_CHAMBER } from "./querys";

const useGetDataChamber = ({ uidUser, uidVoting, type }) => {
  const [dataChamber, setDataChamber] = useState(dataChamberModel);
  const { data: dChamber, loading: lChamber } = useQuery(GET_DATA_CHAMBER, {
    variables: {
      dataChamber: {
        uidUser,
        uidVoting,
        type,
      },
    },
  });
  useEffect(() => {
    if (dChamber && dChamber.getDataChamber) {
      if (dChamber.getDataChamber.response.code === 200) {
        setDataChamber(dChamber.getDataChamber);
      }
    }
  }, [dChamber]);
  return { dataChamber, loading: lChamber };
};

export default useGetDataChamber;
