import { gql } from "@apollo/client";

export const ADD_CONGRESS_TOPIC = gql`
  mutation AddCongressTopic(
    $topic: String!
    $type: CongressTopicsType!
    $uidParent: String!
  ) {
    addCongressTopic(topic: $topic, type: $type, uidParent: $uidParent) {
      response {
        code
        message
      }
    }
  }
`;

export const EDIT_CONGRESS_TOPIC = gql`
  mutation EditCongressTopic($uidCongressTopic: String!, $topic: String!) {
    editCongressTopic(uidCongressTopic: $uidCongressTopic, topic: $topic) {
      response {
        code
        message
      }
    }
  }
`;

export const CHANGE_CONGRESS_TOPIC = gql`
  mutation ChangeStatusCongressTopic(
    $uidCongressTopic: String!
    $status: Int!
  ) {
    changeStatusCongressTopic(
      uidCongressTopic: $uidCongressTopic
      status: $status
    ) {
      response {
        code
        message
      }
    }
  }
`;
