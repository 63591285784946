import { useState } from "react";
import { useMutation } from "@apollo/client";

import { ADD_TOPIC } from "./mutations";

const useAddTopic = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rqAddTopic, { loading: ll }] = useMutation(ADD_TOPIC, {
    onCompleted: ({ createTopic }) => {
      setLoading(false);
      if (onComplete) {
        onComplete(createTopic);
      }
    },
  });

  const addTopic = ({
    name,
    topic,
    image,
    briefDescription,
    broadDescription,
    extensiveDescription,
    duration,
    turn,
    uidUser,
  }) => {
    try {
      setLoading(true);
      rqAddTopic({
        variables: {
          topic: {
            name,
            topic,
            image,
            briefDescription,
            broadDescription,
            extensiveDescription,
            duration: parseInt(duration),
            turn,
            uidUser,
          },
        },
      });
    } catch (x) {
      console.log(x);
    }
  };
  return { loading: loading || ll, addTopic };
};

export default useAddTopic;
