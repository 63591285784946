import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";

import useLazySearchTopics from "../../Hooks/useLazySearchTopics";
import { Input, InputCheck } from "../../components/inputs/Inputs";
import { isAlphaAccents, isValidField } from "../../utils/validations";
import useUI from "../../Hooks/useUI";

const ListVoting = (props) => {
  const { language, userLoggedIn, updateCountTopic } = props;
  const { ui, updateUI, loading: lUi } = useUI();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dataSet, setDataSet] = useState([]);
  const [search, setSearch] = useState("");
  const tableRef = useRef();
  const refSearch = useRef(null);
  const { rqTopics, topics, loading: lTopics } = useLazySearchTopics();
  const columns = [
    { title: "ID", field: "uidVoting", hidden: true },
    {
      title: "",
      field: "image",
      render: (rowData) => (
        <img
          style={{ height: 36, borderRadius: "50%" }}
          src={rowData.image.url}
          alt={rowData.image.name}
        />
      ),
    },
    {
      title: language.txt.nombre,
      field: "name",
    },
    {
      title: language.txt.duracionH,
      field: "duration",
    },
    {
      title: language.txt.camaraOrigen,
      field: "turn",
      render: (rowData) =>
        rowData.turn === "UNKNOWN"
          ? language.txt.desconocido
          : rowData.turn === "DEPUTY"
          ? language.txt.diputados
          : rowData.turn === "SENATOR"
          ? language.txt.senadores
          : language.txt.desconocido,
    },
    {
      title: language.txt.approvalStatus,
      field: "approvalStatus",
      render: (rowData) =>
        rowData.approvalStatus === "NOTAPPROVED"
          ? language.txt.noAprobada
          : language.txt.aprobada,
    },
    {
      title: language.txt.status,
      field: "status",
      render: (rowData) =>
        rowData.status === "INACTIVE"
          ? language.txt.inactiva
          : rowData.status === "DRAF"
          ? language.txt.borrador
          : rowData.status === "PUBLISH"
          ? language.txt.publicada
          : language.txt.desconocido,
    },
  ];

  const handelFilter = () => {
    let status = [];
    if (ui.filter.inactive) {
      status.push(0);
    }
    if (ui.filter.draf) {
      status.push(1);
    }
    if (ui.filter.publish) {
      status.push(2);
    }

    let approvalStatus = [];
    if (ui.filter.noApprovad) {
      approvalStatus.push(0);
    }
    if (ui.filter.approvad) {
      approvalStatus.push(1);
    }
    return {
      status,
      approvalStatus,
    };
  };

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !lTopics && !lUi) {
      setTimeout(() => {
        if (refSearch.current) {
          if (search === refSearch.current.value) {
            const states = handelFilter();
            rqTopics({
              variables: {
                search: {
                  search,
                  uidUser: userLoggedIn.uidUser,
                  limit,
                  offset,
                  status: states.status,
                  approvalStatus: states.approvalStatus,
                },
              },
            });
          }
        }
      }, 600);
    }
  }, [search]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
    const states = handelFilter();
    updateCountTopic({
      search,
      uidUser: userLoggedIn.uidUser,
      limit: topics.count,
      offset,
      status: states.status,
      approvalStatus: states.approvalStatus,
    });
  }, [topics]);

  if (lUi && ui) {
    return "";
  }

  return (
    <div className="bg-white">
      <div className="row my-2 p-2 d-flex justify-content-center align-items-center">
        <div className="col-md-6 my-3">
          <Input
            name="search"
            label={language.txt.buscar + ", " + language.txt.msnBusqueda}
            rows={10}
            value={search}
            textHelper={isAlphaAccents(search).message}
            isValid={isValidField(search, isAlphaAccents(search).valid)}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            ref={refSearch}
          />
        </div>
        <div className="col-md-2 my-3">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-danger dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {language.txt.filtrar}
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  {language.txt.limpiar}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <span className="dropdown-item">{language.txt.status}</span>
              </li>
              <li className="px-2">
                <InputCheck
                  name="inactive"
                  checked={ui.filter.inactive}
                  label={language.txt.inactiva}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filter: {
                        ...ui.filter,
                        inactive: !ui.filter.inactive,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="draf"
                  checked={ui.filter.draf}
                  label={language.txt.borrador}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filter: {
                        ...ui.filter,
                        draf: !ui.filter.draf,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="publish"
                  checked={ui.filter.publish}
                  label={language.txt.publicada}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filter: {
                        ...ui.filter,
                        publish: !ui.filter.publish,
                      },
                    });
                  }}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <span className="dropdown-item">
                  {language.txt.approvalStatus}
                </span>
              </li>
              <li className="px-2">
                <InputCheck
                  name="approvad"
                  checked={ui.filter.approvad}
                  label={language.txt.aprobada}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filter: {
                        ...ui.filter,
                        approvad: !ui.filter.approvad,
                      },
                    });
                  }}
                />
              </li>
              <li className="px-2">
                <InputCheck
                  name="noApprovad"
                  checked={ui.filter.noApprovad}
                  label={language.txt.noAprobada}
                  onChange={() => {
                    updateUI({
                      ...ui,
                      filter: {
                        ...ui.filter,
                        noApprovad: !ui.filter.noApprovad,
                      },
                    });
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <MaterialTable
            title=""
            tableRef={tableRef}
            columns={columns}
            options={{
              search: false,
              maxBodyHeight: "550px",
              initialPage: offset,
              pageSize: limit,
              pageSizeOptions: [
                parseInt(limit / 2),
                limit,
                limit * 5,
                limit * 10,
              ],
            }}
            data={() =>
              new Promise((resolve) => {
                let _dataSet = [];
                topics.topics.forEach((topic) => {
                  dataSet.push({
                    uidVoting: topic.uidVoting,
                    image: topic.image,
                    name: topic.name,
                    duration: topic.duration,
                    status: topic.status,
                    approvalStatus: topic.approvalStatus,
                    turn: topic.turn,
                  });
                });
                setDataSet(_dataSet);
                resolve({
                  data: dataSet, // your data array
                  page: offset, // current page number
                  totalCount: topics.count + limit, // total row number
                });
              })
            }
            onChangePage={(e) => {
              const states = handelFilter();
              setOffset(e);
              rqTopics({
                variables: {
                  search: {
                    search,
                    uidUser: userLoggedIn.uidUser,
                    limit,
                    offset: e,
                    status: states.status,
                    approvalStatus: states.approvalStatus,
                  },
                },
              });
            }}
            onChangeRowsPerPage={(e) => {
              if (e !== 0) {
                const states = handelFilter();
                setLimit(e);
                rqTopics({
                  variables: {
                    search: {
                      search,
                      uidUser: userLoggedIn.uidUser,
                      limit: e,
                      offset,
                      status: states.status,
                      approvalStatus: states.approvalStatus,
                    },
                  },
                });
              }

              tableRef.current && tableRef.current.onQueryChange();
            }}
            onSearchChange={(e) => {
              const states = handelFilter();
              setSearch(e);
              rqTopics({
                variables: {
                  search: {
                    search: e,
                    uidUser: userLoggedIn.uidUser,
                    limit,
                    offset,
                    status: states.status,
                    approvalStatus: states.approvalStatus,
                  },
                },
              });
            }}
            isLoading={lTopics}
            actions={[
              {
                icon: "edit",
                tooltip: language.txt.editar,
                title: "",
                onClick: (event, rowData) =>
                  props.history.push("/votaciones/editar/" + rowData.uidVoting),
              },
            ]}
            localization={{
              header: {
                actions: "",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ListVoting;
