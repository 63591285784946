import React from "react";

import styles from "./styles.module.scss";

export const InputCaret = (props) => {
  const {
    name,
    type,
    label,
    icon,
    required,
    placeholder,
    value,
    onChange,
    disabled,
    isValid,
    textHelper,
  } = props;
  return (
    <div className={`form-floating ${styles.ContCaret}`}>
      <input
        id={`id-${name}`}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        disabled={disabled}
        required={required}
        placeholder={placeholder ? placeholder : label}
        className={`form-control`}
      />
      <label htmlFor={`id-${name}`}>{label ? label : ""}</label>
      <i className={`${icon} ${isValid ? "" : "text-danger"}`}></i>
      {isValid ? (
        ""
      ) : (
        <p className="text-danger">
          {textHelper ? textHelper : "Valor no valido"}
        </p>
      )}
    </div>
  );
};

export const Input = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    label,
    required,
    placeholder,
    value,
    onChange,
    disabled,
    isValid,
    textHelper,
  } = props;
  return (
    <div className={`form-floating`}>
      <input
        id={`id-${name}`}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        disabled={disabled}
        required={required}
        placeholder={placeholder ? placeholder : label}
        className={`form-control`}
        ref={ref}
      />
      <label htmlFor={`id-${name}`}>{label ? label : ""}</label>
      {isValid ? (
        ""
      ) : (
        <p className="text-danger">
          {textHelper ? textHelper : "Valor no valido"}
        </p>
      )}
    </div>
  );
});

export const InputCheck = (props) => {
  const { checked, name, label, onChange, disabled } = props;
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        checked={checked}
        id={"id-" + name}
        disabled={disabled}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={"id-" + name}>
        {label}
      </label>
    </div>
  );
};

export const InputArea = (props) => {
  const {
    name,
    label,
    required,
    placeholder,
    value,
    onChange,
    disabled,
    isValid,
    textHelper,
    rows,
  } = props;

  return (
    <div className="form-floating">
      <textarea
        className="form-control h-100"
        placeholder={placeholder ? placeholder : label}
        rows={rows}
        name={name}
        id={`id-${name}`}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
      ></textarea>
      <label htmlFor={`id-${name}`}>{label ? label : ""}</label>
      {isValid ? (
        ""
      ) : (
        <p className="text-danger">
          {textHelper ? textHelper : "Valor no valido"}
        </p>
      )}
    </div>
  );
};

/*
<div className="form-group">
<label htmlFor={props.id}>{props.label}</label>
<select
  id={props.id}
  className="custom-select custom-select-sm"
  name={props.name}
  defaultValue={props.value}
  onChange={props.actualizarState}
>
  <React.Fragment>
    {props.options.map((item, i) => (
      <option value={item.valor} key={i}>
        {item.texto}
      </option>
    ))}
  </React.Fragment>
</select>
</div>
*/
export const InputSelect = (props) => {
  const { name, value, onChange, options } = props;
  return (
    <div className="form-floating">
      <select
        id={`id-${name}`}
        name={name}
        value={value}
        className="form-select"
        aria-label="Status"
        onChange={onChange}
      >
        {options.map((item, i) => (
          <option value={item.value} key={i}>
            {item.text}
          </option>
        ))}
      </select>
      <label htmlFor={`id-${name}`}>{props.label}</label>
    </div>
  );
};
