import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { congressMailboxesModel } from "../apollo/ContextApp";
import { GET_CONGRESS_MAILBOX } from "./querys";

const useLazyGetCongressMailbox = () => {
  const [topics, setTopics] = useState(congressMailboxesModel);
  const [rqMailbox, { loading: lTopics }] = useLazyQuery(GET_CONGRESS_MAILBOX, {
    onCompleted: (data) => {
      setTopics(data.getCongressMailbox);
    },
    fetchPolicy: "no-cache",
  });

  return { rqMailbox, topics, loading: lTopics, setTopics };
};

export default useLazyGetCongressMailbox;
