import { gql } from "@apollo/client";

export const UPDATE_POLITIC = gql`
  mutation UpdatePolitics($id: Int!, $politic: String!) {
    updatePolitics(id: $id, politic: $politic) {
      code
      message
    }
  }
`;
