import React, { useState, useEffect } from "react";
import parsePhoneNumber from "libphonenumber-js";

import { AccordionItem } from "../../../components/accordion/Accordion";
import { Input } from "../../../components/inputs/Inputs";
import {
  isAlphaAccents,
  isEmail,
  isValidField,
} from "../../../utils/validations";
import { TypeUsers, CatUserStatus } from "../../../components/catalogs";
import { MessageAction } from "../../../components/messages/Messages";

const PersonalInformation = (props) => {
  const {
    language,
    nameParent,
    user,
    handleUpdateUser,
    onSubmitPI,
    loading,
    message,
    textBtn,
  } = props;
  const [isValidPhone, setIsValidPhone] = useState(false);

  const validateForm = () => {
    if (
      isEmail(user.email).valid &&
      isAlphaAccents(user.names).valid &&
      isAlphaAccents(user.surname1).valid &&
      isAlphaAccents(user.surname2).valid &&
      isValidPhone &&
      user.type != "UNKNOWN" &&
      user.status != "UNKNOWN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(user.phone, "MX");
    if (phoneNumber) {
      setIsValidPhone(phoneNumber.isValid());
    } else {
      setIsValidPhone(false);
    }
  }, [user]);

  return (
    <AccordionItem
      title={language.txt.informacionBasica}
      name={"data-user"}
      nameParent={nameParent}
      expanded={true}
      icon={"bi-person-lines-fill"}
    >
      <form onSubmit={onSubmitPI} className="needs-validation">
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name="email"
              type="text"
              label={language.txt.email}
              textHelper={isEmail(user.email).message}
              value={user.email}
              isValid={isValidField(user.email, isEmail(user.email).valid)}
              onChange={handleUpdateUser}
            />
          </div>
          <div className="col-md-6">
            <Input
              name="names"
              type="text"
              label={language.txt.nombres}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.names).message}
              value={user.names}
              isValid={isValidField(
                user.names,
                isAlphaAccents(user.names).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name="surname1"
              type="text"
              label={language.txt.apellido1}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.surname1).message}
              value={user.surname1}
              isValid={isValidField(
                user.surname1,
                isAlphaAccents(user.surname1).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
          <div className="col-md-6">
            <Input
              name="surname2"
              type="text"
              label={language.txt.apellido2}
              icon="bi-person-fill"
              textHelper={isAlphaAccents(user.surname2).message}
              value={user.surname2}
              isValid={isValidField(
                user.surname2,
                isAlphaAccents(user.surname2).valid
              )}
              onChange={handleUpdateUser}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <Input
              name="phone"
              type="text"
              label={language.txt.telefono}
              icon="bi-person-fill"
              textHelper={language.txt.msnEPhone}
              value={user.phone}
              isValid={isValidPhone}
              onChange={(e) => {
                const { value } = e.target;
                let _phone = "";
                const phoneNumber = parsePhoneNumber(value, "MX");
                if (phoneNumber) {
                  setIsValidPhone(phoneNumber.isValid());
                  _phone = phoneNumber.formatNational();
                } else {
                  _phone = value;
                }
                handleUpdateUser({ target: { value: _phone, name: "phone" } });
              }}
            />
          </div>
          <div className="col-md-6">
            <TypeUsers
              {...props}
              value={user.type}
              onChange={handleUpdateUser}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <CatUserStatus
              {...props}
              value={user.status}
              onChange={handleUpdateUser}
            />
          </div>
        </div>
        <div className="card-body">
          <hr className="dropdown-divider text-muted" />
          <MessageAction message={message} />
          <button
            type="submit"
            className="btn btn-danger my-3"
            disabled={!validateForm() || loading}
          >
            {textBtn}
          </button>
        </div>
      </form>
    </AccordionItem>
  );
};

export default PersonalInformation;
