import { gql } from "@apollo/client";

export const EDIT_TOPIC = gql`
  mutation EditTopic($topic: EditTopic!) {
    editTopic(topic: $topic) {
      response {
        code
        message
      }
      uidVoting
      name
      topic
      image {
        url
        name
      }
      briefDescription
      broadDescription {
        url
        name
      }
      extensiveDescription {
        url
        name
      }
      duration
      creationDate
      user {
        uidUser
        names
        surname1
        surname2
        icon
        email
        phone
      }
      status
      approvalStatus
      turn
      nextProcess
    }
  }
`;

export const UPDATE_DATA_CHAMBER = gql`
  mutation UpdateDataChamber($data: InUpdateChamber!) {
    updateDataChamber(data: $data) {
      code
      message
    }
  }
`;

export const CHANGE_STATUS_TOPIC = gql`
  mutation ChangeStatusTopic(
    $status: TopicStatusType!
    $uidVoting: String!
    $uidUser: String!
  ) {
    changeStatusTopic(
      status: $status
      uidVoting: $uidVoting
      uidUser: $uidUser
    ) {
      response {
        code
        message
      }
      uidVoting
      name
      topic
      image {
        url
        name
      }
      briefDescription
      broadDescription {
        url
        name
      }
      extensiveDescription {
        url
        name
      }
      duration
      creationDate
      user {
        uidUser
        names
        surname1
        surname2
        icon
        email
        phone
      }
      status
      approvalStatus
    }
  }
`;
