import React, { useContext } from "react";

import { InputArea } from "../../components/inputs/Inputs";
import { MessageAction } from "../../components/messages/Messages";
import { isAlphaAccents, isValidField } from "../../utils/validations";
import { Context } from "./Context";

const FormTopic = (props) => {
  const { language } = props;
  const {
    loading,
    message,
    topic,
    action,
    setTopic,
    addTopic,
    editTopic,
    handleRestart,
  } = useContext(Context);

  const onSubmit = (e) => {
    e.preventDefault();
    if (action == 0 || action == 3) {
      addTopic(e);
    } else if (action == 1 || action == 4) {
      editTopic(e);
    }
  };

  const validateForm = () => {
    if (isAlphaAccents(topic).valid) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <InputArea
              name="topic"
              label={language.txt.tema}
              rows={5}
              value={topic}
              textHelper={isAlphaAccents(topic).message}
              isValid={isValidField(topic, isAlphaAccents(topic).valid)}
              onChange={(e) => {
                setTopic(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <br />
            <MessageAction message={message} />
            <button
              type="submit"
              className="btn btn-danger my-3 me-3"
              disabled={!validateForm() || loading}
            >
              {language.txt.guardar}
            </button>
            <button
              type="button"
              className="btn btn-danger my-3 me-3"
              disabled={loading}
              onClick={() => {
                handleRestart();
              }}
            >
              {language.txt.cancelar}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormTopic;
