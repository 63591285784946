import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { AccordionItem } from "../../components/accordion/Accordion";
import { Input } from "../../components/inputs/Inputs";
import { CatApprovalStatus } from "../../components/catalogs";
import { MessageAction } from "../../components/messages/Messages";

import { isValidField, esNumero } from "../../utils/validations";

import useGetDataChamber from "../../Hooks/useGetDataChamber";
import { UPDATE_DATA_CHAMBER } from "./mutations";

const { REACT_APP_TYPE_CHAMBER_SENATOR } = process.env;

const SenateChamber = (props) => {
  const { language, nameParent, userLoggedIn } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });
  const { dataChamber: dChamber, loading: lChamber } = useGetDataChamber({
    uidUser: userLoggedIn.uidUser,
    uidVoting: props.match.params.id,
    type: REACT_APP_TYPE_CHAMBER_SENATOR,
  });

  const [dataChamber, setDataChamber] = useState(dChamber);
  const [rqUpdateDataChamber, { loading: lDUChaber }] = useMutation(
    UPDATE_DATA_CHAMBER,
    {
      onCompleted: ({ updateDataChamber }) => {
        console.log(updateDataChamber);
        setMessage({ ...updateDataChamber });
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
        }, 4000);
      },
    }
  );

  const handleUpdateDataChamber = (e) => {
    const { value, name } = e.target;
    setDataChamber({
      ...dataChamber,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (
      esNumero(dataChamber.inFavor).valid &&
      dataChamber.inFavor != 0 &&
      esNumero(dataChamber.against).valid &&
      dataChamber.against != 0 &&
      esNumero(dataChamber.abstention).valid &&
      dataChamber.abstention != 0 &&
      dataChamber.approvalStatus != "UNKNOWN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });
    const { inFavor, against, abstention, approvalStatus } = dataChamber;
    rqUpdateDataChamber({
      variables: {
        data: {
          uidUser: userLoggedIn.uidUser,
          uidVoting: props.match.params.id,
          type: REACT_APP_TYPE_CHAMBER_SENATOR,
          inFavor: parseInt(inFavor),
          against: parseInt(against),
          abstention: parseInt(abstention),
          approvalStatus,
        },
      },
    });
  };

  useEffect(() => {
    setDataChamber(dChamber);
  }, [dChamber]);

  if (lChamber || lDUChaber) {
    return "";
  }

  return (
    <AccordionItem
      title={language.txt.camarSenadores}
      subTitle={"📌" + language.txt.msnCamaraSenadores}
      name={"voting-senate"}
      nameParent={nameParent}
      expanded={true}
      icon={"bi bi-people-fill"}
    >
      <form
        onSubmit={onSubmit}
        className="mb-5"
        style={{ position: "relative" }}
      >
        <div className="row bg-white">
          <div className="col-md-4">
            <Input
              name="inFavor"
              type="text"
              label={language.txt.aFavor}
              icon="bi-person-fill"
              textHelper={esNumero(dataChamber.inFavor).message}
              value={dataChamber.inFavor}
              isValid={isValidField(
                dataChamber.inFavor,
                esNumero(dataChamber.inFavor).valid
              )}
              onChange={handleUpdateDataChamber}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="against"
              type="text"
              label={language.txt.enContra}
              icon="bi-person-fill"
              textHelper={esNumero(dataChamber.against).message}
              value={dataChamber.against}
              isValid={isValidField(
                dataChamber.against,
                esNumero(dataChamber.against).valid
              )}
              onChange={handleUpdateDataChamber}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="abstention"
              type="text"
              label={language.txt.abstencion}
              icon="bi-person-fill"
              textHelper={esNumero(dataChamber.abstention).message}
              value={dataChamber.abstention}
              isValid={isValidField(
                dataChamber.abstention,
                esNumero(dataChamber.abstention).valid
              )}
              onChange={handleUpdateDataChamber}
            />
          </div>
        </div>
        <div className="row bg-white my-4">
          <div className="col-md-4">
            <CatApprovalStatus
              language={language}
              value={dataChamber.approvalStatus}
              onChange={handleUpdateDataChamber}
            />
          </div>
        </div>
        <div className="card-body">
          <hr className="dropdown-divider text-muted" />
          <MessageAction message={message} />
          <button
            type="submit"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || lDUChaber || lChamber}
          >
            {language.txt.editar}
          </button>
        </div>
      </form>
    </AccordionItem>
  );
};

export default SenateChamber;
