import React from "react";

import { parliamentariansModel } from "../../apollo/ContextApp";

//export const reducer = (state, action) => {};

export const Context = React.createContext({
  parliamentarians: parliamentariansModel,
  setParliamentarians: () => {},
  loading: false,
});
