import { uiModel } from "../apollo/ContextApp";

/**
 * Recupera el token de localStorage
 */
export const getToken = () => {
  const t = localStorage.getItem("__t");
  let token = { token: "", uidToken: "" };
  if (t && t !== '""') {
    token = JSON.parse(t);
  }
  return token;
};

/**
 * Recupera la confiuración de la UI
 */
export const getUi = () => {
  const _ui = localStorage.getItem("__ui");
  let ui = { ...uiModel };
  if (_ui && _ui !== '""') {
    ui = JSON.parse(_ui);
  }
  return ui;
};

/**
 * Recupera el uid del usuario logueado
 */
export const getUidu = () => {
  const _uidU = localStorage.getItem("__uidu");
  let uidU = "";
  if (_uidU) {
    uidU = _uidU;
  }
  return uidU;
};
