import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { parliamentariansModel } from "../apollo/ContextApp";
import { GET_PARLIAMENTARIANS } from "./querys";

const useGetParliamentarians = () => {
  const [parliamentarians, setParliamentarians] = useState(
    parliamentariansModel
  );
  const { data: dParliamentarians, loading: lParliamentarians } = useQuery(
    GET_PARLIAMENTARIANS
  );

  useEffect(() => {
    if (dParliamentarians && dParliamentarians.getParliamentarians) {
      if (dParliamentarians.getParliamentarians.response.code === 200) {
        setParliamentarians(dParliamentarians.getParliamentarians);
      }
    }
  }, [dParliamentarians]);
  return { parliamentarians, loading: lParliamentarians, setParliamentarians };
};

export default useGetParliamentarians;
