import React from "react";

import { userModel } from "../../../apollo/ContextApp";

export const reducer = (state, action) => {
  switch (action.type) {
    case "ROL_USERS":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            users: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            users: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }

    case "ROL_VOTING":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            voting: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            voting: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }

    case "ROL_PARLIAMENTARY":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            parliamentary: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            parliamentary: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }

    case "ROL_CONGRESS":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }

    case "ROL_CONGRESS":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }

    case "ROL_POLITICS":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            politics: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            politics: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }
    case "ROL_CONGRESS":
      if (action.name == "active" && !action.value) {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              active: false,
              create: false,
              edit: false,
              delete: false,
              publish: false,
              show: false,
            },
          },
        };
      } else {
        return {
          ...state,
          rol: {
            ...state.rol,
            congressTopic: {
              ...action.current,
              [action.name]: action.value,
            },
          },
        };
      }
    case "ROL_TIME_ZONE":
      return {
        ...action.current,
        rol: {
          ...action.current.rol,
          timeZone: parseInt(action.value),
        },
      };
  }
};

export const Context = React.createContext({
  user: {
    ...userModel,
  },
  handleUpdateUserRol: () => {},
  reducer: () => {},
  onSubmitRoles: () => {},
});
