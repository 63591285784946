import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import styles from "./styles.module.scss";
import {
  isEmail,
  isValidField,
  isAlphanumericEGC,
} from "../../utils/validations";
import { InputCaret, InputCheck } from "../../components/inputs/Inputs";
import { MessageAction } from "../../components/messages/Messages";
import useAuth from "../../Hooks/useAuth";

import useUI from "../../Hooks/useUI";

const Login = (props) => {
  const { language, history, refetch } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });
  const { ui, updateUI } = useUI();

  const { login } = useAuth({
    onComplete: (login) => {
      setMessage({
        message: login.message,
        code: login.code,
      });
      if (login.code === 200) {
        setMessage({
          message: language.txt.sesionOk,
          code: login.code,
        });
        localStorage.setItem("__t", JSON.stringify(login));
        updateUI({
          ...ui,
          remember: refresh,
        });
        setTimeout(async () => {
          await refetch();
          history.push("/");
        }, 3000);
      }
    },
  });

  const validateForm = () => {
    const isValid = !isEmail(email).valid || !password;
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });
    login({ email, password, refresh });
  };

  const handeOnChange = (e) => {
    const { value, name } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    if (name === "password") {
      setPassword(value);
    }
    var forms = document.querySelectorAll(".needs-validation");
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.classList.add("was-validated");
    });
  };

  useEffect(() => {
    if (window) {
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
    }
  }, []);

  return (
    <div className="container">
      <div className={`center-abs`}>
        <div className={`card ${styles.card}`}>
          <div className={`${styles.img}`}>
            <img
              className="card-img-top"
              src={ui.urlImg}
              alt="Sistema de votaciones"
            />
          </div>
          <div className="card-body">
            <h1 className="card-title my-3 text-center">
              {language.txt.sitio}
            </h1>
            <form onSubmit={onSubmit} className="needs-validation">
              <div className="my-3">
                <InputCaret
                  name="email"
                  type="email"
                  label={language.txt.email}
                  icon="bi-person-fill"
                  textHelper={isEmail(email).message}
                  value={email}
                  isValid={isValidField(email, isEmail(email).valid)}
                  onChange={handeOnChange}
                />
              </div>
              <div className="my-3">
                <InputCaret
                  name="password"
                  type="password"
                  label={language.txt.contrasenia}
                  icon="bi-lock-fill"
                  textHelper={isAlphanumericEGC(password).message}
                  value={password}
                  isValid={isValidField(
                    password,
                    isAlphanumericEGC(password).valid
                  )}
                  onChange={handeOnChange}
                />
              </div>
              <div className="my-1">
                <InputCheck
                  name="refresh"
                  checked={refresh}
                  label={language.txt.recordar}
                  onChange={() => {
                    setRefresh(!refresh);
                  }}
                />
              </div>
              <MessageAction message={message} />

              <div className="text-center my-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={validateForm()}
                >
                  {language.txt.acceder}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
