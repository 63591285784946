import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { createUploadLink } from "apollo-upload-client";
import axios from "axios";
import jwt from "jsonwebtoken";

import cache from "./Cache";
import { getToken, getUi, getUidu } from "../utils/utils";

const { REACT_APP_API_URL, NODE_ENV } = process.env;

/*
const httpLink = createHttpLink({
  uri: REACT_APP_API_URL,
});
*/

const link = createUploadLink({
  uri: REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token.token}`,
    },
  };
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: "data.data.refreshToken",
  // No need to refresh if token exists and is still valid
  isTokenValidOrUndefined: () => {
    //console.log("isTokenValidOrUndefined");
    let ui = getUi();
    let token = getToken();
    //console.log(ui);
    //console.log(token);

    if (token && token.token === "") {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      return true;
    } else {
      if (ui.remember) {
        if (jwt.decode(token.token)?.exp * 1000 > Date.now()) {
          return true;
        }
      } else {
        if (jwt.decode(token.token)?.exp * 1000 < Date.now()) {
          if (window.location.pathname !== "/login") {
            window.location.href = "/login";
          }
        }
        return true;
      }
    }
  },
  fetchAccessToken: () => {
    //console.log("fetchAccessToken");
    let uidUser = getUidu();
    let token = getToken();

    const query = `
        mutation RefreshToken($uidToken: String!, $uidUser: String!) {
            refreshToken(uidToken: $uidToken, uidUser: $uidUser) {
            code
            message
            token
            uidToken
            }
        }
    `;

    return axios(REACT_APP_API_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${token.token}`,
      },
      data: {
        query,
        variables: {
          uidToken: token.uidToken,
          uidUser,
        },
      },
    });
  },
  handleFetch: (accessToken) => {},
  handleResponse: (operation, accessTokenField) => (response) => {
    const dataToken = response.data.data.refreshToken;
    if (
      dataToken.code === 200 &&
      dataToken.token !== "" &&
      dataToken.uidToken !== ""
    ) {
      localStorage.setItem("__t", JSON.stringify(dataToken));
    } else {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  },
  handleError: (error) => {},
});

const client = new ApolloClient({
  link: authLink.concat(refreshLink).concat(link),
  cache,
  connectToDevTools: NODE_ENV !== "production",
});

export default client;
