import React, { useContext, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useMutation } from "@apollo/client";

import { AccordionItem } from "../../components/accordion/Accordion";
import { MessageAction } from "../../components/messages/Messages";
import { Context } from "./Context";
import { UPDATE_POLITIC } from "./mutations";

const Cookies = (props) => {
  const { language, nameParent, userLoggedIn } = props;
  const [message, setMessage] = useState({ message: "", code: 200 });
  const { loading, setLoading, cookies, setCookies } = useContext(Context);

  const [rqUpdatePolitic, { loading: lUP }] = useMutation(UPDATE_POLITIC, {
    onCompleted: ({ updatePolitics }) => {
      setMessage(updatePolitics);
      setTimeout(() => {
        setMessage({ message: "", code: 200 });
        setLoading(false);
      }, 3000);
    },
  });

  const updateData = () => {
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });
    rqUpdatePolitic({
      variables: {
        id: cookies.id,
        politic: cookies.politic,
      },
    });
  };

  if (!cookies) {
    return "";
  }
  return (
    <div>
      <AccordionItem
        title={language.txt.polCookies}
        subTitle={""}
        name={"cookies"}
        nameParent={nameParent}
        expanded={false}
        icon={""}
      >
        <div>
          <CKEditor
            editor={ClassicEditor}
            data={cookies.politic}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCookies({
                ...cookies,
                politic: data,
              });
            }}
          />
          <div>
            {userLoggedIn.rol.politics.edit ? (
              <>
                <MessageAction message={message} />
                <button
                  type="button"
                  class="fw-bold btn btn-danger btn-sm mt-4"
                  onClick={updateData}
                  disabled={loading || lUP}
                >
                  <i className="bi bi-cloud-arrow-up-fill me-2"></i>
                  {language.txt.guardar}
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </AccordionItem>
    </div>
  );
};

export default Cookies;
