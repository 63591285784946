import React, { useEffect, useContext, useReducer } from "react";

import { AccordionItem } from "../../../components/accordion/Accordion";
import { CatTimeZone } from "../../../components/catalogs";
import { InputCheck } from "../../../components/inputs/Inputs";
import { MessageAction } from "../../../components/messages/Messages";
import { Context } from "./Context";

const RolUsers = (props) => {
  const { language, userLoggedIn } = props;
  const { user, reducer, handleUpdateUserRol } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        users: {
          ...state.rol.users,
        },
      });
    }
  }, [state]);

  //console.log(user.rol);

  return (
    <>
      {userLoggedIn.rol.users.active ? (
        <div className="col-md-4">
          <h3 className="h5">
            <i className="bi bi-person-check-fill me-2"></i>
            <span>{language.txt.usuarios}</span>
          </h3>
          <hr className="dropdown-divider" />
          <InputCheck
            name="users.active"
            checked={user.rol.users.active}
            label={language.txt.activo}
            onChange={(e) => {
              const { checked } = e.target;
              dispatch({
                type: "ROL_USERS",
                name: "active",
                value: checked,
                current: { ...user.rol.users },
              });
            }}
          />
          {userLoggedIn.rol.users.create ? (
            <InputCheck
              name="users.create"
              checked={user.rol.users.create}
              label={language.txt.crear}
              disabled={!user.rol.users.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_USERS",
                  name: "create",
                  value: checked,
                  current: { ...user.rol.users },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.users.edit ? (
            <InputCheck
              name="users.edit"
              checked={user.rol.users.edit}
              label={language.txt.editar}
              disabled={!user.rol.users.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_USERS",
                  name: "edit",
                  value: checked,
                  current: { ...user.rol.users },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.users.delete ? (
            <InputCheck
              name="users.delete"
              checked={user.rol.users.delete}
              label={language.txt.eliminar}
              disabled={!user.rol.users.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_USERS",
                  name: "delete",
                  value: checked,
                  current: { ...user.rol.users },
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const RolVoting = (props) => {
  const { language, userLoggedIn } = props;
  const { user, reducer, handleUpdateUserRol } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        voting: {
          ...state.rol.voting,
        },
      });
    }
  }, [state]);

  return (
    <>
      {userLoggedIn.rol.voting.active ? (
        <div className="col-md-4">
          <h3 className="h5">
            <i className="bi bi-hand-thumbs-up-fill me-2"></i>
            <span>{language.txt.votaciones}</span>
          </h3>
          <hr className="dropdown-divider" />
          <InputCheck
            name="voting.active"
            checked={user.rol.voting.active}
            label={language.txt.activo}
            onChange={(e) => {
              const { checked } = e.target;
              dispatch({
                type: "ROL_VOTING",
                name: "active",
                value: checked,
                current: { ...user.rol.voting },
              });
            }}
          />
          {userLoggedIn.rol.voting.create ? (
            <InputCheck
              name="voting.create"
              checked={user.rol.voting.create}
              label={language.txt.crear}
              disabled={!user.rol.voting.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_VOTING",
                  name: "create",
                  value: checked,
                  current: { ...user.rol.voting },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.voting.edit ? (
            <InputCheck
              name="voting.edit"
              checked={user.rol.voting.edit}
              label={language.txt.editar}
              disabled={!user.rol.voting.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_VOTING",
                  name: "edit",
                  value: checked,
                  current: { ...user.rol.voting },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.voting.delete ? (
            <InputCheck
              name="voting.delete"
              checked={user.rol.voting.delete}
              label={language.txt.eliminar}
              disabled={!user.rol.voting.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_VOTING",
                  name: "delete",
                  value: checked,
                  current: { ...user.rol.voting },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.voting.publish ? (
            <InputCheck
              name="voting.publish"
              checked={user.rol.voting.publish}
              label={language.txt.publicar}
              disabled={!user.rol.voting.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_VOTING",
                  name: "publish",
                  value: checked,
                  current: { ...user.rol.voting },
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const RolParliamentary = (props) => {
  const { language, userLoggedIn } = props;
  const { user, reducer, handleUpdateUserRol } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        parliamentary: {
          ...state.rol.parliamentary,
        },
      });
    }
  }, [state]);

  //console.log(user.rol);

  return (
    <>
      {userLoggedIn.rol.parliamentary.active ? (
        <div className="col-md-4">
          <h3 className="h5">
            <i className="bi bi-x-diamond-fill me-2"></i>
            <span>{language.txt.InfoEstParlamentaria}</span>
          </h3>
          <hr className="dropdown-divider" />
          <InputCheck
            name="parliamentary.active"
            checked={user.rol.parliamentary.active}
            label={language.txt.activo}
            onChange={(e) => {
              const { checked } = e.target;
              dispatch({
                type: "ROL_PARLIAMENTARY",
                name: "active",
                value: checked,
                current: { ...user.rol.parliamentary },
              });
            }}
          />
          {userLoggedIn.rol.parliamentary.create ? (
            <InputCheck
              name="parliamentary.create"
              checked={user.rol.parliamentary.create}
              label={language.txt.crear}
              disabled={!user.rol.parliamentary.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_PARLIAMENTARY",
                  name: "create",
                  value: checked,
                  current: { ...user.rol.parliamentary },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.parliamentary.edit ? (
            <InputCheck
              name="parliamentary.edit"
              checked={user.rol.parliamentary.edit}
              label={language.txt.editar}
              disabled={!user.rol.parliamentary.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_PARLIAMENTARY",
                  name: "edit",
                  value: checked,
                  current: { ...user.rol.parliamentary },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.parliamentary.delete ? (
            <InputCheck
              name="parliamentary.delete"
              checked={user.rol.parliamentary.delete}
              label={language.txt.eliminar}
              disabled={!user.rol.parliamentary.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_PARLIAMENTARY",
                  name: "delete",
                  value: checked,
                  current: { ...user.rol.parliamentary },
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const RolcongressTopic = (props) => {
  const { language, userLoggedIn } = props;
  const { user, reducer, handleUpdateUserRol } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        congressTopic: {
          ...state.rol.congressTopic,
        },
      });
    }
  }, [state]);

  //console.log(user.rol);

  return (
    <>
      {userLoggedIn.rol.congressTopic.active ? (
        <div className="col-md-4">
          <h3 className="h5">
            <i className="bi bi-person-lines-fill me-2"></i>
            <span>{language.txt.temasCongreso}</span>
          </h3>
          <hr className="dropdown-divider" />
          <InputCheck
            name="congressTopic.active"
            checked={user.rol.congressTopic.active}
            label={language.txt.activo}
            onChange={(e) => {
              const { checked } = e.target;
              dispatch({
                type: "ROL_CONGRESS",
                name: "active",
                value: checked,
                current: { ...user.rol.congressTopic },
              });
            }}
          />
          {userLoggedIn.rol.congressTopic.create ? (
            <InputCheck
              name="congressTopic.create"
              checked={user.rol.congressTopic.create}
              label={language.txt.crear}
              disabled={!user.rol.congressTopic.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_CONGRESS",
                  name: "create",
                  value: checked,
                  current: { ...user.rol.congressTopic },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.congressTopic.edit ? (
            <InputCheck
              name="congressTopic.edit"
              checked={user.rol.congressTopic.edit}
              label={language.txt.editar}
              disabled={!user.rol.congressTopic.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_CONGRESS",
                  name: "edit",
                  value: checked,
                  current: { ...user.rol.congressTopic },
                });
              }}
            />
          ) : (
            ""
          )}
          {userLoggedIn.rol.congressTopic.delete ? (
            <InputCheck
              name="congressTopic.delete"
              checked={user.rol.congressTopic.delete}
              label={language.txt.eliminar}
              disabled={!user.rol.congressTopic.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_CONGRESS",
                  name: "delete",
                  value: checked,
                  current: { ...user.rol.congressTopic },
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const RolPolitics = (props) => {
  const { language, userLoggedIn } = props;
  const { user, reducer, handleUpdateUserRol } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        politics: {
          ...state.rol.politics,
        },
      });
    }
  }, [state]);

  //console.log(user.rol);

  return (
    <>
      {userLoggedIn.rol.politics.active ? (
        <div className="col-md-4">
          <h3 className="h5">
            <i className="bi bi-bookmark-check-fill me-2"></i>
            <span>{language.txt.politicas}</span>
          </h3>
          <hr className="dropdown-divider" />
          <InputCheck
            name="politics.active"
            checked={user.rol.politics.active}
            label={language.txt.activo}
            onChange={(e) => {
              const { checked } = e.target;
              dispatch({
                type: "ROL_POLITICS",
                name: "active",
                value: checked,
                current: { ...user.rol.politics },
              });
            }}
          />

          {userLoggedIn.rol.politics.edit ? (
            <InputCheck
              name="politics.edit"
              checked={user.rol.politics.edit}
              label={language.txt.editar}
              disabled={!user.rol.politics.active}
              onChange={(e) => {
                const { checked } = e.target;
                dispatch({
                  type: "ROL_POLITICS",
                  name: "edit",
                  value: checked,
                  current: { ...user.rol.politics },
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const Roles = (props) => {
  const { language, nameParent, textBtn } = props;

  const {
    user,
    handleUpdateUserRol,
    onSubmitRoles,
    loading,
    message,
    reducer,
  } = useContext(Context);

  const [state, dispatch] = useReducer(reducer, user);

  useEffect(() => {
    if (state) {
      handleUpdateUserRol({
        timeZone: state.rol.timeZone,
      });
    }
  }, [state]);

  return (
    <AccordionItem
      title={language.txt.asignarRoles}
      subTitle={language.txt.msnRoles}
      name={"roles-user"}
      nameParent={nameParent}
      expanded={true}
      icon={"bi bi-gear-fill"}
    >
      <form onSubmit={onSubmitRoles} className="needs-validation">
        <div className="row mb-3">
          <div className="col-md-4">
            <CatTimeZone
              {...props}
              value={user.rol.timeZone}
              onChange={(e) => {
                const { value } = e.target;
                dispatch({
                  type: "ROL_TIME_ZONE",
                  name: "timeZone",
                  value,
                  current: { ...user.rol },
                });
              }}
            />
          </div>
          <RolUsers {...props} />
          <RolVoting {...props} />
        </div>
        <div className="row mb-3">
          <RolParliamentary {...props} />
          <RolcongressTopic {...props} />
          <RolPolitics {...props} />
        </div>
        <div className="card-body">
          <hr className="dropdown-divider text-muted" />
          <MessageAction message={message} />
          <button
            type="submit"
            className="btn btn-danger my-3"
            disabled={loading}
          >
            {textBtn}
          </button>
        </div>
      </form>
    </AccordionItem>
  );
};

export default Roles;
