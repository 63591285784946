import React from "react";

import withContainer from "../withContainer";

//Pages

const P404 = (props) => {
  const { language } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="alert alert-danger text-center" role="alert">
            <h1>
              <i class="bi bi-emoji-frown me-2"></i>
              {"404"}
            </h1>

            <h3 className="my-4">{language.txt.msn404}</h3>
            <a className="h2 text-dark fw-bold" href="/">
              {"Inicio"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withContainer(P404)({});
