import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import withContainer from "../withContainer";
import { WinActions } from "../../components/messages/Messages";
import ListTopic from "./ListTopic";
import Mailbox from "./Mailbox";

import { Context } from "./Context";
import FormTopic from "./FormTopic";
import FormTopicOK from "./FormTopicOK";

import useLazyGetCongressTopics from "../../Hooks/useLazyGetCongressTopics";

import {
  ADD_CONGRESS_TOPIC,
  EDIT_CONGRESS_TOPIC,
  CHANGE_CONGRESS_TOPIC,
} from "./mutations";

const { Provider } = Context;

const CongressTopic = (props) => {
  const { language, userLoggedIn } = props;
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([0, 1]);
  const [showFormT, setShowFormT] = useState(false);
  const [showFormTOk, setShowFormTOK] = useState(false);
  const [topic, setTopic] = useState("");
  const [uidTopic, setUidTopic] = useState("");
  const [type, setType] = useState("UNKNOWN");
  const [newStatus, setNewStatus] = useState(0);
  const [titleModal, setTitleModal] = useState("");
  const [action, setAction] = useState(-1);
  const [uidParent, setUidParent] = useState("");
  const [message, setMessage] = useState({ message: "", code: 200 });

  const { rqTopics, topics, loading: lTopics } = useLazyGetCongressTopics();

  const [rqAddCT, { loading: lCT }] = useMutation(ADD_CONGRESS_TOPIC, {
    onCompleted: ({ addCongressTopic }) => {
      setMessage({ ...addCongressTopic.response });
      if (addCongressTopic.response.code === 200) {
        rqTopics({
          variables: {
            status,
          },
        });

        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
          setShowFormT(false);
          handleRestart();
        }, 1000);
      } else {
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
        }, 3000);
      }
    },
  });

  const [rqEditCT, { loading: lECT }] = useMutation(EDIT_CONGRESS_TOPIC, {
    onCompleted: ({ editCongressTopic }) => {
      setMessage({ ...editCongressTopic.response });
      if (editCongressTopic.response.code === 200) {
        rqTopics({
          variables: {
            status,
          },
        });

        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
          setShowFormT(false);
          handleRestart();
        }, 1000);
      } else {
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
        }, 3000);
      }
    },
  });

  const [rqChangeCT, { loading: lChCT }] = useMutation(CHANGE_CONGRESS_TOPIC, {
    onCompleted: ({ changeStatusCongressTopic }) => {
      setMessage({ ...changeStatusCongressTopic.response });
      if (changeStatusCongressTopic.response.code === 200) {
        rqTopics({
          variables: {
            status,
          },
        });

        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
          setShowFormT(false);
          handleRestart();
        }, 1000);
      } else {
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
          setLoading(false);
        }, 3000);
      }
    },
  });

  const handleAddTopic = () => {
    setShowFormT(true);
    setType("TOPIC");
    setAction(0);
    setTitleModal(language.txt.nuevoTema);
    setUidParent("");
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleEditTopic = (_topic) => {
    setTopic(_topic.topic);
    setUidTopic(_topic.uidCongressTopic);
    setShowFormT(true);
    setType("TOPIC");
    setAction(1);
    setTitleModal(language.txt.editarTema);
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleChangeStatusTopic = (_topic, _status) => {
    setNewStatus(_status);
    setTopic(_topic.topic);
    setUidTopic(_topic.uidCongressTopic);
    setType("TOPIC");
    setAction(2);
    setShowFormTOK(true);
    setTitleModal(language.txt.actualizarEstado);
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleAddTopicChild = (_topic) => {
    console.log(_topic);
    setShowFormT(true);
    setType("SUBTOPIC");
    setAction(3);
    setTitleModal(language.txt.nuevoSubTema);
    setUidParent(_topic.uidCongressTopic);
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleEditTopicChild = (_topic) => {
    setTopic(_topic.topic);
    setUidTopic(_topic.uidCongressTopic);
    setShowFormT(true);
    setType("SUBTOPIC");
    setAction(4);
    setTitleModal(language.txt.editarSubTema);
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleChangeStatusTopicChild = (_topic, _status) => {
    setNewStatus(_status);
    setTopic(_topic.topic);
    setUidTopic(_topic.uidCongressTopic);
    setType("SUBTOPIC");
    setAction(5);
    setShowFormTOK(true);
    setTitleModal(language.txt.actualizarEstado);
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleRestart = () => {
    setAction(-1);
    setTopic("");
    setType("UNKNOWN");
    setMessage({ message: "", code: 200 });
    setShowFormT(false);
    setShowFormTOK(false);
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  };

  const addTopic = (e) => {
    e.preventDefault();
    setMessage({ code: 200, message: language.txt.cargando });
    rqAddCT({
      variables: {
        topic,
        type,
        uidParent,
      },
    });
  };

  const editTopic = (e) => {
    e.preventDefault();
    setMessage({ code: 200, message: language.txt.cargando });
    rqEditCT({
      variables: {
        uidCongressTopic: uidTopic,
        topic,
      },
    });
  };

  const changeStatusTopic = () => {
    setMessage({ code: 200, message: language.txt.cargando });
    rqChangeCT({
      variables: {
        uidCongressTopic: uidTopic,
        status: newStatus,
      },
    });
  };

  useEffect(() => {
    rqTopics({
      variables: {
        status,
      },
    });
  }, []);

  useEffect(() => {
    rqTopics({
      variables: {
        status,
      },
    });
  }, [status]);

  return (
    <Provider
      value={{
        loading: lTopics || lCT || lECT || lChCT,
        message,
        showFormT,
        topic,
        topics,
        uidTopic,
        setUidTopic,
        setTopic,
        type,
        action,
        setAction,
        setType,
        addTopic,
        editTopic,
        handleAddTopic,
        handleEditTopic,
        handleChangeStatusTopic,
        changeStatusTopic,
        handleAddTopicChild,
        handleEditTopicChild,
        handleChangeStatusTopicChild,
        handleRestart,
      }}
    >
      <div className="container pt-4">
        {showFormT ? (
          <WinActions id="modal-add-t" title={titleModal}>
            <FormTopic {...props} />
          </WinActions>
        ) : (
          ""
        )}

        {showFormTOk ? (
          <WinActions id="modal-add-ok" title={titleModal}>
            <FormTopicOK {...props} />
          </WinActions>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{language.txt.inicio}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language.txt.temasCongreso}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">
              {language.txt.temasCongreso}
            </h1>

            {userLoggedIn.rol.congressTopic.create && !loading && !showFormT ? (
              <button
                type="button"
                className="fw-bold btn btn-danger btn-sm"
                onClick={handleAddTopic}
              >
                <i className="bi-plus-circle fw-bold me-2"></i>
                <span>{language.txt.agregarTema}</span>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-12">
            <div>
              <ul className="nav nav-tabs" id="tabCongress" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="topics-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#topics"
                    type="button"
                    role="tab"
                    aria-controls="topics"
                    aria-selected="true"
                  >
                    {language.txt.temasCongreso}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="buzon-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#buzon"
                    type="button"
                    role="tab"
                    aria-controls="buzon"
                    aria-selected="true"
                  >
                    {language.txt.buzon}
                  </button>
                </li>
              </ul>
              <div className="tab-content bg-white" id="tabCongress">
                <div
                  className="tab-pane fade show active"
                  id="topics"
                  role="tabpanel"
                  aria-labelledby="topics-tab"
                >
                  <ListTopic {...props} />
                </div>
                <div
                  className="tab-pane fade"
                  id="buzon"
                  role="tabpanel"
                  aria-labelledby="buzon-tab"
                >
                  <Mailbox {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default withContainer(CongressTopic)({});
