import { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { SEARCH_USER } from "./querys";

const useLazySearchUsers = () => {
  const [users, setUsers] = useState({ users: [], count: 0 });
  const [rqUsers, { loading: lUsers }] = useLazyQuery(SEARCH_USER, {
    onCompleted: (data) => {
      setUsers(data.searchUser);
    },
  });
  return { rqUsers, users, loading: lUsers };
};

export default useLazySearchUsers;
