const es = function () {
  this.sitio = "SIVIL";
  this.inicio = "Inicio";
  this.email = "Correo electrónico";
  this.contrasenia = "Contraseña";
  this.contraseniaR = "Repetir Contraseña";
  this.acceder = "Acceder";
  this.recuperarPass = "Recuperar contraseña";
  this.cargando = "Cargando...";
  this.sesionOk = "Login exitoso, cargando datos de su cuenta…";
  this.recordar = "Recordar";
  this.miperfil = "Editar mi perfil";
  this.cerrarSesion = "Cerrar sesión";
  this.dashboard = "Dashboard";
  this.votaciones = "Votaciones";
  this.misDatos = "Modificar mis datos";
  this.nombres = "Nombres";
  this.nombre = "Nombre";
  this.imagen = "Imagen";
  this.status = "Estatus";
  this.approvalStatus = "Resultado";
  this.duracion = "Duración en minutos.";
  this.duracionH = "Duración Min.";
  this.apellido1 = "Apellido paterno";
  this.apellido2 = "Apellido materno";
  this.telefono = "Teléfono";
  this.actualizar = "Actualizar";
  this.actPass = "Actualizar contraseña";
  this.descargar = "Descargar";
  this.adminVotacion = "Administrador de votaciones";
  this.agregarVotacion = "Agregar votación";
  this.temaParticipacion = "Tema de la votación";
  this.seleccionArchivo = "Seleccionar archivo";
  this.seleccionar = "Seleccionar";
  this.soltarArchivo = "Suelta archivo aquí";
  this.briefDescription = "Descripción breve";
  this.broadDescription = "Descripción amplia";
  this.extensiveDescription = "Descripción extensa";
  this.errorformatoArchivoImg =
    "Error de formato, por favor seleccione una imagen JPG o PNG.";
  this.errorformatoArchivoPDF =
    "Error de formato, por favor seleccione una archivo PDF";

  this.editarVotacion = "Editar votación";

  this.usuarios = "Usuarios";
  this.agregarUsuario = "Agregar usuario";
  this.editarUsuario = "Editar usuario";
  this.informacionBasica = "Información básica";
  this.asignarRoles = "Asignar roles";
  this.zonaHoraria = "Zona horaria";

  this.crear = "Crear";
  this.editar = "Editar";
  this.eliminar = "Eliminar";
  this.publicar = "Publicar";
  this.aFavor = "A favor";
  this.enContra = "En contra";
  this.abstencion = "Abstención";

  this.buscar = "Buscar";
  this.filtrar = "Filtrar";
  this.limpiar = "Limpiar";
  this.inactiva = "Inactiva";
  this.inactivos = "Inactivos";
  this.activo = "Activo";
  this.activos = "Activos";
  this.borrador = "Borrador";
  this.cambiarABorrador = "Cambiar a borrador";
  this.publicada = "Publicada";
  this.desconocido = "Desconocido";
  this.noAprobada = "No aprobada";
  this.aprobada = "Aprobada";
  this.tipo = "Tipo";
  this.administrador = "Administrador";
  this.editor = "Editor";
  this.votantegeneral = "Votante general";
  this.votante = "Votante";
  this.camarDiputados = "Cámara de Diputados";
  this.camarSenadores = "Cámara de Senadores";
  this.diputados = "Diputados";
  this.senadores = "Senadores";
  this.camaras = "Cámaras";
  this.dof = "Publicación en el Diario Oficial de la Federación";
  this.camaraActual = "Cámara actual";
  this.tramiteSiguiente = "Tramite siguiente";
  this.temaTratado = "Tema tratado en";
  this.camaraOrigen = "Cámara origen";

  this.estParlamentaria = "Estadística parlamentaria";
  this.InfoEstParlamentaria =
    "Información legislativa para la estadística parlamentaria";
  this.origen = "Origen";
  this.rConst = "Reformas constitucionales";
  this.rLeg = "Reformas legales";
  this.decretos = "Decretos";
  this.total = "total";
  this.legislatura = "Legislatura";
  this.errorFormatoBlanco =
    "Error de formato, por favor revise que no existan campos en blanco o vacíos.";
  this.preguntaEliminar = "¿Está seguro de eliminar este registro?";
  this.complementos = "Complementos";
  this.agendaLegislativa = "Agenda legislativa";
  this.procesoLegislativo = "Etapas del proceso legislativo federal en México";
  this.funcionesEstructura = "Funciones y estructura";
  this.cancelar = "Cancelar";
  this.vaParaDiputados = "Turno a Cámara de Diputados";
  this.vaAlSenado = "Turno a Cámara de Senadores";
  this.histVoting = "Historial de votaciones ciudadanas";
  this.dePuDofLe = "Decretos publicados en el DOF por legislatura";
  this.dicDeTiDi = "Dictámenes de Ley o Decreto por tipo de dictamen";
  this.DePuDofPro = "Decretos publicados en el DOF por proponente";
  this.MoMaLePro = "Modificaciones al marco legal por proponente";
  this.diagrama1 =
    "Diagrama 1. Proceso Legislativo Ordinario simplificado de acuerdo al inciso a) de una sola vuelta";
  this.diagrama2 =
    "Diagrama 2. Proceso Legislativo Ordinario ampliado de acuerdo al inciso d) partiendo del paso 6 del Diagrama 1";
  this.diagrama3 =
    "Diagrama 3. Proceso Legislativo Ordinario ampliado de acuerdo al inciso e) partiendo del paso 6 del Diagrama 1";
  this.diagrama4 =
    "Diagrama 4. Proceso Legislativo Ordinario de Reforma Constitucional partiendo del paso 6 del Diagrama 1.";
  this.diagrama5 =
    "Diagrama 5. Proceso Legislativo Especial relativo a la Iniciativa Preferente (Sólo reformas a leyes secundarias).";
  this.diagrama6 =
    "Diagrama 6. Proceso Legislativo Ordinario de Veto Presidencial de acuerdo a los incisos b) y c) partiendo del paso 7 del Diagrama 1.";
  this.diagrama7 =
    "Diagrama 7. Proceso Legislativo para la discusión y votación del Presupuesto de Egresos de la Federación (PEF).";

  this.temasCongreso = "Temas del congreso";
  this.tema = "Tema";
  this.subtemas = "Subtemas";
  this.agregarTema = "Agregar tema";
  this.nuevoTema = "Nuevo tema";
  this.nuevoSubTema = "Nuevo subtema";
  this.editarTema = "Editar tema";
  this.editarSubTema = "Editar subtema";
  this.actualizarEstado = "Actualizar estatus";
  this.guardar = "Guardar";
  this.buzon = "Buzón";
  this.conteoTemasCongreso = "Conteo de temas sugeridos por los usuarios";

  this.politicas = "Políticas";
  this.polPrivacidad = "Política de privacidad";
  this.polCookies = "Política de cookies";
  this.polTerms = "Términos y condiciones";

  this.msnCPass =
    "Modificación de contraseña, asegúrese de que tenga al menos 6 caracteres, mayúsculas y minúsculas y algunos caracteres espaciales.";
  this.msnEPhone = "Formato de teléfono no valido.";
  this.msnPassN1 =
    "Nivel Bajo, introduzca mínimo 6 caracteres con mayúsculas, números y al menos un caracter especial.";
  this.msnPassN2 =
    "Nivel Medio, introduzca mínimo 6 caracteres con mayúsculas, números y al menos un caracter especial.";
  this.msnPassN3 = "Contraseña fuerte.";
  this.msnPassDif = "Las contraseñas no son iguales, verifique por favor.";
  this.msnSeleccioArchivo =
    "O si prefieres, arrastra y suelta el archivo en este espacio.";
  this.msnCrearEncuesta =
    "Usted puede agregar una imagen .PNG o .JPG para dar una mejor presentación a su votación, adicionalmente podrá cargar un par de archivos en formato PDF, para que los usuarios obtengan más información respecto al tema.";
  this.msnBusqueda =
    "Los acentos y caracteres especiales influyen en la búsqueda.";
  this.msCreacionUsuarioOk = "Creación exitosa, espere un momento por favor.";
  this.msnRoles =
    "Elige que acciones podrá realizar el usuario en los diferentes módulos del sistema.";
  this.msnCamaraDiputado =
    "Estos datos representan la participación por parte de la Cámara de Diputados, respecto a este tema.";
  this.msnCamaraSenadores =
    "Estos datos representan la participación por parte de la Cámara de Senadores, respecto a este tema.";
  this.msnEdicion = "¿Está seguro de continuar?";

  this.msn404 =
    "Lo sentimos, hemos movido esta página, por favor dirígete al inicio para ver más opciones, gracias.";
  this.msnPermissionDenied =
    "Lo sentimos, su cuenta no tiene los privilegios para ver esta sección, por favor póngase en contacto con soporte para más detalles, gracias.";
};
export default es;
