import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import InputFile from "./InputFile";
import { MessageAction } from "../messages/Messages";
import useGetAccessories from "../../Hooks/useGetAccessories";
import useUpload from "../../Hooks/useUpload";

import { ADD_ACCESSORY } from "./mutations";

const initialFileModel = {
  file: undefined,
  response: { code: 0, message: "" },
  url: "-",
  name: "",
};

const UploadAccesory = (props) => {
  const { language, title, typeFile, accessoryType } = props;
  const [msn, setMsn] = useState({ message: "", code: 200 });
  const [initialFile, setInitialFile] = useState(initialFileModel);
  const { accessories, loading: lGetAccessories } = useGetAccessories();

  const [rqAddAccesory, { loading: lAdd }] = useMutation(ADD_ACCESSORY, {
    onCompleted: ({ addAccessory }) => {
      if (addAccessory.response.code == 200) {
        setMsn({
          ...addAccessory.response,
        });
      } else {
        setMsn({
          ...addAccessory.response,
        });
      }
      setTimeout(() => {
        setMsn({ message: "", code: 200 });
      }, 4000);
    },
  });

  const { upload, loading } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setInitialFile({
          ...initialFile,
          url: uploadFile.url,
        });

        rqAddAccesory({
          variables: {
            accessory: {
              url: uploadFile.url,
              name: initialFile.name,
              type: accessoryType,
            },
          },
        });
      }
      setMsn({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsn({ message: "", code: 200 });
      }, 3000);
    },
  });

  const handleLoadFile = (files) => {
    setMsn({ message: language.txt.cargando, code: 200 });
    if (files[0].type === typeFile) {
      setInitialFile({
        file: files[0],
        response: { code: 0, message: "" },
        url: "",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setInitialFile({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsn({ message: language.txt.errorformatoArchivoPDF, code: 401 });
    }
  };

  useEffect(() => {
    if (accessories.accessories.length > 0) {
      const funtionS = accessories.accessories.filter(
        (a) => a.type == accessoryType
      )[0];
      if (funtionS) {
        setInitialFile({
          ...initialFile,
          url: funtionS.url,
          name: funtionS.name,
        });
      }
    }
  }, [accessories]);

  return (
    <>
      <p>{title}</p>
      {loading || lGetAccessories || lAdd ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <InputFile
            {...props}
            reset={true}
            name="functionStructure"
            handleLoadFile={handleLoadFile}
            _drag={false}
            _dragCounter={1}
          >
            <p>
              {initialFile &&
              initialFile !== language.txt.errorformatoArchivoPDF
                ? initialFile.name
                : ""}
            </p>
          </InputFile>
          <MessageAction message={msn} />
        </>
      )}
    </>
  );
};

export default UploadAccesory;
