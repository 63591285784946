import React, { useState } from "react";

//import styles from "./styles.module.scss";
import { Input } from "../../../components/inputs/Inputs";
import { AccordionItem } from "../../../components/accordion/Accordion";
import useChangePassword from "../../../Hooks/useChangePassword";
import { MessageAction } from "../../../components/messages/Messages";

import {
  isAlphanumericEGC,
  isValidField,
  verifyPasswords,
} from "../../../utils/validations";

const ChangePassword = (props) => {
  const { language, uidUser } = props;
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [message, setMessage] = useState({ message: "", code: 200 });
  const { changePass } = useChangePassword({
    onComplete: (response) => {
      setMessage({
        message: response.message,
        code: response.code,
      });
      if (response.code === 200) {
        setTimeout(() => {
          setMessage({ message: "", code: 200 });
        }, 5000);
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage({
      message: language.txt.cargando,
      code: 200,
    });

    changePass({
      uidUser,
      password,
    });
  };
  const validateForm = () => {
    if (
      isAlphanumericEGC(password).valid &&
      isAlphanumericEGC(password1).valid
    ) {
      if (password !== password1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <AccordionItem
        title={language.txt.actPass}
        subTitle={language.txt.msnCPass}
        name={"data-pass"}
        nameParent={"accordion-user"}
        expanded={true}
        icon={"bi-key-fill"}
      >
        <form onSubmit={onSubmit} className="needs-validation">
          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                name="password"
                type="password"
                label={language.txt.contrasenia}
                icon="bi-person-fill"
                textHelper={isAlphanumericEGC(password).message}
                value={password}
                isValid={isValidField(
                  password,
                  isAlphanumericEGC(password).valid
                )}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <Input
                name="password1"
                type="password"
                label={language.txt.contraseniaR}
                icon="bi-person-fill"
                textHelper={isAlphanumericEGC(password1).message}
                value={password1}
                isValid={isValidField(
                  password1,
                  isAlphanumericEGC(password1).valid
                )}
                onChange={(e) => {
                  setPassword1(e.target.value);
                }}
              />
            </div>
          </div>
          {verifyPasswords(password) > 0 ? (
            <div className="row my-2">
              <div className="col-md-12">
                <p
                  className={
                    verifyPasswords(password) === 1
                      ? "my-3 alert alert-danger"
                      : verifyPasswords(password) === 2
                      ? "my-3 alert alert-warning"
                      : verifyPasswords(password) === 3
                      ? "my-3 alert alert-success"
                      : "my-3 alert alert-danger"
                  }
                >
                  {verifyPasswords(password) === 1
                    ? language.txt.msnPassN1
                    : verifyPasswords(password) === 2
                    ? language.txt.msnPassN2
                    : verifyPasswords(password) === 3
                    ? language.txt.msnPassN3
                    : ""}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {password !== "" && password1 !== "" && password !== password1 ? (
            <div className="row my-2">
              <div className="col-md-12">
                <p className={"my-3 alert alert-danger"}>
                  {language.txt.msnPassDif}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="card-body">
            <hr className="dropdown-divider text-muted" />
            <MessageAction message={message} />
            <button
              type="submit"
              className="btn btn-danger"
              disabled={!validateForm()}
            >
              {language.txt.actualizar}
            </button>
          </div>
        </form>
      </AccordionItem>
    </>
  );
};

export default ChangePassword;
