import es from "./es";

class Langueage {
  constructor(lan) {
    let languege = lan === undefined || lan === null || lan === "" ? "es" : lan;
    if (languege === "es") {
      this.txt = new es();
    }
  }
}
export default Langueage;
