import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { accessoriesModel } from "../apollo/ContextApp";
import { GET_ACCESSORIES } from "./querys";

const useGetAccessories = () => {
  const [accessories, setAccessories] = useState(accessoriesModel);
  const { data: dA, loading: lDA } = useQuery(GET_ACCESSORIES);

  useEffect(() => {
    if (dA && dA.getAccessories) {
      if (dA.getAccessories.response.code === 200) {
        setAccessories(dA.getAccessories);
      }
    }
  }, [dA]);

  return { accessories, loading: lDA };
};
export default useGetAccessories;
