import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";

import styles from "./styles.module.scss";
import { AccordionItem } from "../../components/accordion/Accordion";
import { InputArea, Input } from "../../components/inputs/Inputs";
import InputFileDragAndDropImg from "../../components/inputs/InputFileDragAndDropImg";
import InputFile from "../../components/inputs/InputFile";
import {
  CatNextProcess,
  CatApprovalStatus,
  CatTurns,
} from "../../components/catalogs";
import { MessageAction } from "../../components/messages/Messages";
import {
  isAlphaAccents,
  isValidField,
  esNumero,
} from "../../utils/validations";

import { Context } from "./Context";
import { EDIT_TOPIC, CHANGE_STATUS_TOPIC } from "./mutations";
import useUpload from "../../Hooks/useUpload";

const { REACT_APP_URL_IMG_DEFAULT } = process.env;

const initialFile = {
  file: undefined,
  response: { code: 0, message: "" },
  url: "-",
  name: "",
};

const BtnEdit = (props) => {
  const {
    language,
    validateForm,
    loading: ll,
    steps,
    setSteps,
    userLoggedIn,
  } = props;
  const { vote, topic, margeVote } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });

  const [rqEditUser, { loading: lEditUser }] = useMutation(EDIT_TOPIC, {
    onCompleted: ({ editTopic }) => {
      setMessage({ ...editTopic.response });

      if (editTopic.response.code === 200) {
        margeVote({
          ...editTopic,
        });
        setTimeout(() => {
          setSteps(1);
        }, 4000);
      }

      setTimeout(() => {
        setMessage({ message: "", code: 200 });
        setLoading(false);
      }, 4000);
    },
  });

  const onEdit = () => {
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });

    rqEditUser({
      variables: {
        topic: {
          uidVoting: topic.uidVoting,
          name: vote.name,
          topic: vote.topic,
          image: {
            url: vote.image.url,
            name: vote.image.name,
          },
          briefDescription: vote.briefDescription,
          broadDescription: {
            url: vote.broadDescription.url,
            name: vote.broadDescription.name,
          },
          extensiveDescription: {
            url: vote.extensiveDescription.url,
            name: vote.extensiveDescription.name,
          },
          duration: parseInt(vote.duration),
          approvalStatus: vote.approvalStatus,
          uidUser: userLoggedIn.uidUser,
          turn: vote.turn,
          nextProcess: vote.nextProcess,
        },
      },
    });
  };
  return (
    <div>
      {steps === 1 ? (
        <button
          type="button"
          className="btn btn-danger my-3 me-3"
          disabled={!validateForm() || loading || ll || lEditUser}
          role="button"
          onClick={() => {
            setSteps(2);
          }}
        >
          {language.txt.editar}
        </button>
      ) : steps === 2 ? (
        <div className="d-flex align-items-center">
          <span className="h3 me-3">{language.txt.msnEdicion}</span>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || ll || lEditUser}
            onClick={onEdit}
          >
            {language.txt.editar}
          </button>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || ll || lEditUser}
            role="button"
            onClick={() => {
              setSteps(1);
            }}
          >
            {language.txt.cancelar}
          </button>
        </div>
      ) : (
        ""
      )}
      <MessageAction message={message} />
    </div>
  );
};

const BtnPublish = (props) => {
  const {
    language,
    validateForm,
    loading: ll,
    steps,
    setSteps,
    userLoggedIn,
  } = props;
  const { margeVote, topic } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });

  const [rqChange, { loading: lChange }] = useMutation(CHANGE_STATUS_TOPIC, {
    onCompleted: ({ changeStatusTopic }) => {
      setMessage({ ...changeStatusTopic.response });

      if (changeStatusTopic.response.code === 200) {
        margeVote({
          ...changeStatusTopic,
        });
        setTimeout(() => {
          setSteps(1);
        }, 4000);
      }

      setTimeout(() => {
        setMessage({ message: "", code: 200 });
        setLoading(false);
      }, 4000);
    },
  });

  const onPublish = () => {
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });

    rqChange({
      variables: {
        status: "PUBLISH",
        uidVoting: topic.uidVoting,
        uidUser: userLoggedIn.uidUser,
      },
    });
  };
  return (
    <div>
      {steps === 1 ? (
        <button
          type="button"
          className="btn btn-danger my-3 me-3"
          disabled={!validateForm() || loading}
          role="button"
          onClick={() => {
            setSteps(3);
          }}
        >
          {language.txt.publicar}
        </button>
      ) : steps === 3 ? (
        <div className="d-flex align-items-center">
          <span className="h3 me-3">{language.txt.msnEdicion}</span>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || ll}
            onClick={onPublish}
          >
            {language.txt.publicar}
          </button>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading}
            role="button"
            onClick={() => {
              setSteps(1);
            }}
          >
            {language.txt.cancelar}
          </button>
        </div>
      ) : (
        ""
      )}
      <MessageAction message={message} />
    </div>
  );
};

const BtnDraf = (props) => {
  const {
    language,
    validateForm,
    loading: ll,
    steps,
    setSteps,
    userLoggedIn,
  } = props;
  const { topic, margeVote } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });

  const [rqChange, { loading: lChange }] = useMutation(CHANGE_STATUS_TOPIC, {
    onCompleted: ({ changeStatusTopic }) => {
      setMessage({ ...changeStatusTopic.response });

      if (changeStatusTopic.response.code === 200) {
        margeVote({
          ...changeStatusTopic,
        });
        setTimeout(() => {
          setSteps(1);
        }, 4000);
      }

      setTimeout(() => {
        setMessage({ message: "", code: 200 });
        setLoading(false);
      }, 4000);
    },
  });

  const onDraf = () => {
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });

    rqChange({
      variables: {
        status: "DRAF",
        uidVoting: topic.uidVoting,
        uidUser: userLoggedIn.uidUser,
      },
    });
  };
  return (
    <div>
      {steps === 1 ? (
        <button
          type="button"
          className="btn btn-danger my-3 me-3"
          disabled={!validateForm() || loading}
          role="button"
          onClick={() => {
            setSteps(4);
          }}
        >
          {language.txt.cambiarABorrador}
        </button>
      ) : steps === 4 ? (
        <div className="d-flex align-items-center">
          <span className="h3 me-3">{language.txt.msnEdicion}</span>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || ll}
            onClick={onDraf}
          >
            {language.txt.cambiarABorrador}
          </button>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading}
            role="button"
            onClick={() => {
              setSteps(1);
            }}
          >
            {language.txt.cancelar}
          </button>
        </div>
      ) : (
        ""
      )}
      <MessageAction message={message} />
    </div>
  );
};

const BtnDelete = (props) => {
  const {
    language,
    validateForm,
    loading: ll,
    steps,
    setSteps,
    userLoggedIn,
  } = props;
  const { topic, margeVote } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", code: 200 });

  const [rqChange, { loading: lChange }] = useMutation(CHANGE_STATUS_TOPIC, {
    onCompleted: ({ changeStatusTopic }) => {
      setMessage({ ...changeStatusTopic.response });

      if (changeStatusTopic.response.code === 200) {
        margeVote({
          ...changeStatusTopic,
        });
        setTimeout(() => {
          setSteps(1);
        }, 4000);
      }

      setTimeout(() => {
        setMessage({ message: "", code: 200 });
        setLoading(false);
      }, 4000);
    },
  });

  const onDelete = () => {
    setLoading(true);
    setMessage({ code: 200, message: language.txt.cargando });

    rqChange({
      variables: {
        status: "INACTIVE",
        uidVoting: topic.uidVoting,
        uidUser: userLoggedIn.uidUser,
      },
    });
  };
  return (
    <div>
      {steps === 1 ? (
        <button
          type="button"
          className="btn btn-danger my-3 me-3"
          disabled={!validateForm() || loading}
          role="button"
          onClick={() => {
            setSteps(5);
          }}
        >
          {language.txt.eliminar}
        </button>
      ) : steps === 5 ? (
        <div className="d-flex align-items-center">
          <span className="h3 me-3">{language.txt.msnEdicion}</span>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading || ll}
            onClick={onDelete}
          >
            {language.txt.eliminar}
          </button>
          <button
            type="button"
            className="btn btn-danger my-3 me-3"
            disabled={!validateForm() || loading}
            role="button"
            onClick={() => {
              setSteps(1);
            }}
          >
            {language.txt.cancelar}
          </button>
        </div>
      ) : (
        ""
      )}
      <MessageAction message={message} />
    </div>
  );
};

const Voting = (props) => {
  const { language, nameParent } = props;
  const [steps, setSteps] = useState(1);
  const { vote, margeVote, loading, topic } = useContext(Context);

  const [fileImg, setFileImg] = useState(initialFile);
  const [broadDescription, setBroadDescription] = useState(initialFile);
  const [extensiveDescription, setExtensiveDescription] = useState(initialFile);

  const [msnImg, setMsnImg] = useState({ message: "", code: 200 });
  const [msnBroad, setMsnBroad] = useState({ message: "", code: 200 });
  const [msnExtensive, setMsnExtensive] = useState({ message: "", code: 200 });

  const { upload, loading: lUpload } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setFileImg({
          ...fileImg,
          url: uploadFile.url,
        });
        margeVote({
          image: {
            url: uploadFile.url,
            name: fileImg.name,
          },
        });
      }
      setMsnImg({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnImg({ message: "", code: 200 });
      }, 3000);
    },
  });
  const { upload: uploadBroad, loading: lUploadBroad } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setBroadDescription({
          ...broadDescription,
          url: uploadFile.url,
        });
        margeVote({
          broadDescription: {
            url: uploadFile.url,
            name: broadDescription.name,
          },
        });
      }
      setMsnBroad({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnBroad({ message: "", code: 200 });
      }, 3000);
    },
  });

  const { upload: uploadExtensive, loading: lUploadExtensive } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setExtensiveDescription({
          ...extensiveDescription,
          url: uploadFile.url,
        });
        margeVote({
          extensiveDescription: {
            url: uploadFile.url,
            name: extensiveDescription.name,
          },
        });
      }
      setMsnExtensive({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnExtensive({ message: "", code: 200 });
      }, 3000);
    },
  });

  const handleUpdateVote = (e) => {
    const { value, name } = e.target;
    margeVote({
      [name]: value,
    });
  };

  const handleImage = (files) => {
    setMsnImg({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
      setFileImg({
        file: files[0],
        response: { code: 0, message: "" },
        url: "-",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setFileImg({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnImg({ message: language.txt.errorformatoArchivoImg, code: 401 });
    }
  };

  const handleBroadDescription = (files) => {
    setMsnBroad({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "application/pdf") {
      setBroadDescription({
        file: files[0],
        response: { code: 0, message: "" },
        url: "",
        name: files[0].name,
      });
      uploadBroad({ file: files[0], isPublic: true });
    } else {
      setBroadDescription({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnBroad({ message: language.txt.errorformatoArchivoPDF, code: 401 });
    }
  };

  const handleExtensiveDescription = (files) => {
    setMsnExtensive({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "application/pdf") {
      setExtensiveDescription({
        file: files[0],
        response: { code: 0, message: "" },
        url: "",
        name: files[0].name,
      });
      uploadExtensive({ file: files[0], isPublic: true });
    } else {
      setExtensiveDescription({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnExtensive({
        message: language.txt.errorformatoArchivoPDF,
        code: 401,
      });
    }
  };

  const validateForm = (e) => {
    if (
      fileImg.url !== "-" &&
      broadDescription.url !== "-" &&
      extensiveDescription.url !== "-" &&
      isAlphaAccents(vote.topic).valid &&
      isAlphaAccents(vote.briefDescription).valid &&
      isAlphaAccents(vote.name).valid &&
      esNumero(vote.duration).valid
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (topic.uidVoting !== "") {
      setFileImg({
        ...fileImg,
        url: topic.image.url,
        name: topic.image.name,
      });

      setBroadDescription({
        ...broadDescription,
        url: topic.broadDescription.url,
        name: topic.broadDescription.name,
      });

      setExtensiveDescription({
        ...extensiveDescription,
        url: topic.extensiveDescription.url,
        name: topic.extensiveDescription.name,
      });
    }
  }, [topic]);

  if (
    lUpload ||
    lUploadBroad ||
    lUploadExtensive ||
    loading ||
    vote.response.code != 200
  ) {
    return "";
  }

  return (
    <AccordionItem
      title={language.txt.editarVotacion}
      subTitle={"📌" + language.txt.msnCrearEncuesta}
      name={"voting-data"}
      nameParent={nameParent}
      expanded={true}
      icon={"bi bi-hand-thumbs-up-fill"}
    >
      <div className="row bg-white">
        <div className="col-md-12">
          <div className="mb-5" style={{ position: "relative" }}>
            <div className="row bg-white p-4">
              <div className="col-md-12 p-2">
                <Input
                  name="name"
                  type="text"
                  label={language.txt.nombre}
                  textHelper={isAlphaAccents(vote.name).message}
                  value={vote.name}
                  isValid={isValidField(
                    vote.name,
                    isAlphaAccents(vote.name).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
            </div>
            <div className="row bg-white p-4">
              <div className="col-md-4 p-2">
                <div>
                  <InputFileDragAndDropImg
                    idInputFile={"idFileDragDropImgXML"}
                    dragCounter={0}
                    handleLoadFile={handleImage}
                    reset={false}
                    containerCustom={styles.containerImg}
                    ico={
                      fileImg.url !== "-"
                        ? fileImg.url
                        : REACT_APP_URL_IMG_DEFAULT
                    }
                    language={language}
                    dragCounter={1}
                  ></InputFileDragAndDropImg>
                  <MessageAction message={msnImg} />
                </div>
              </div>
              <div className="col-md-4 p-2">
                <InputArea
                  name="topic"
                  label={language.txt.temaParticipacion}
                  rows={10}
                  value={vote.topic}
                  textHelper={isAlphaAccents(vote.topic).message}
                  isValid={isValidField(
                    vote.topic,
                    isAlphaAccents(vote.topic).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
              <div className="col-md-4 p-2">
                <InputArea
                  name="briefDescription"
                  label={language.txt.briefDescription}
                  rows={10}
                  value={vote.briefDescription}
                  textHelper={isAlphaAccents(vote.briefDescription).message}
                  isValid={isValidField(
                    vote.briefDescription,
                    isAlphaAccents(vote.briefDescription).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
            </div>
            <div className="row bg-white p-4">
              <div className="col-md-4 p-2">
                <p>{language.txt.broadDescription}</p>
                <InputFile
                  {...props}
                  reset={true}
                  name="broadDescription"
                  handleLoadFile={handleBroadDescription}
                  _drag={false}
                  _dragCounter={1}
                >
                  <p>
                    {broadDescription &&
                    broadDescription !== language.txt.errorformatoArchivoPDF
                      ? broadDescription.name
                      : ""}
                  </p>
                </InputFile>
                <MessageAction message={msnBroad} />
              </div>
              <div className="col-md-4 p-2">
                <p>{language.txt.extensiveDescription}</p>
                <InputFile
                  {...props}
                  reset={false}
                  name="extensiveDescription"
                  handleLoadFile={handleExtensiveDescription}
                >
                  <p>
                    {extensiveDescription &&
                    extensiveDescription !== language.txt.errorformatoArchivoPDF
                      ? extensiveDescription.name
                      : ""}
                  </p>
                </InputFile>
                <MessageAction message={msnExtensive} />
              </div>
              <div className="col-md-4 p-2 pt-5">
                <div className="my-2">
                  <Input
                    name="duration"
                    type="text"
                    label={language.txt.duracion}
                    icon="bi-person-fill"
                    textHelper={esNumero(vote.duration).message}
                    value={vote.duration}
                    isValid={isValidField(
                      vote.duration,
                      esNumero(vote.duration).valid
                    )}
                    onChange={handleUpdateVote}
                  />
                </div>

                <div className="my-3">
                  <CatApprovalStatus
                    language={language}
                    value={vote.approvalStatus}
                    onChange={handleUpdateVote}
                  />
                </div>

                <div className="my-3">
                  <CatTurns
                    language={language}
                    value={vote.turn}
                    onChange={handleUpdateVote}
                  />
                </div>

                <div className="my-3">
                  <CatNextProcess
                    language={language}
                    value={vote.nextProcess}
                    onChange={handleUpdateVote}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <hr className="dropdown-divider text-muted" />
              <div className="d-flex justify-content-center align-items-center">
                <BtnEdit
                  {...props}
                  validateForm={validateForm}
                  loading={
                    loading || lUpload || lUploadBroad || lUploadExtensive
                  }
                  setSteps={setSteps}
                  steps={steps}
                />

                {vote.status === "INACTIVE" || vote.status === "PUBLISH" ? (
                  <BtnDraf
                    {...props}
                    validateForm={validateForm}
                    loading={
                      loading || lUpload || lUploadBroad || lUploadExtensive
                    }
                    setSteps={setSteps}
                    steps={steps}
                  />
                ) : vote.status === "DRAF" ? (
                  <BtnPublish
                    {...props}
                    validateForm={validateForm}
                    loading={
                      loading || lUpload || lUploadBroad || lUploadExtensive
                    }
                    setSteps={setSteps}
                    steps={steps}
                  />
                ) : (
                  <div className="alert alert-secondary" role="alert">
                    {language.txt.cargando}
                  </div>
                )}

                {vote.status === "DRAF" || vote.status === "PUBLISH" ? (
                  <BtnDelete
                    {...props}
                    validateForm={validateForm}
                    loading={
                      loading || lUpload || lUploadBroad || lUploadExtensive
                    }
                    setSteps={setSteps}
                    steps={steps}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccordionItem>
  );
};

export default Voting;
