import React from "react";

export const AccordionItem = (props) => {
  const {
    title,
    subTitle,
    name,
    nameParent,
    expanded,
    children,
    icon,
    iconBtn1,
    handleBtn1,
    disabledBtn1,
    iconBtn2,
    handleBtn2,
    disabledBtn2,
  } = props;
  return (
    <div className="accordion-item">
      <h2 className="accordion-header " id={`flush-${name}`}>
        <div
          className={`accordion-button  ${expanded ? "" : "collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#id-flush-${name}`}
          aria-expanded={expanded}
          aria-controls={`#id-flush-${name}`}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              {icon ? <i className={`${icon} iconH me-2`}></i> : ""}
              <span>{title}</span>
            </div>
            <div>
              {iconBtn1 ? (
                <button
                  className="btn"
                  onClick={handleBtn1}
                  disabled={disabledBtn1}
                >
                  <i className={iconBtn1}></i>
                </button>
              ) : (
                ""
              )}
              {iconBtn2 ? (
                <button
                  className="btn"
                  onClick={handleBtn2}
                  disabled={disabledBtn2}
                >
                  <i className={iconBtn2}></i>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </h2>

      <div
        id={`id-flush-${name}`}
        className={`accordion-collapse collapse ${expanded ? "show" : ""}`}
        aria-labelledby={`flush-${name}`}
        data-bs-parent={`#id-${nameParent}`}
      >
        <div className="accordion-body">
          {subTitle ? (
            <div className="mb-4">
              <p className="text-muted">{subTitle}</p>
            </div>
          ) : (
            ""
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export const Accordion = (props) => {
  const { name, children } = props;

  return (
    <div className="accordion" id={`id-${name}`}>
      {children}
    </div>
  );
};
