const { REACT_APP_URL_LOGO } = process.env;

export const responseModel = {
  code: 401,
  message: "",
  __typename: "Response",
};
export const uiModel = {
  lMenu: true,
  remember: false,
  loading: true,
  urlImg: REACT_APP_URL_LOGO,
  filter: {
    inactive: false,
    draf: true,
    publish: true,
    noApprovad: true,
    approvad: true,
  },
  filterUsers: {
    active: true,
    inactive: true,
    admin: true,
    editor: true,
    generalVoter: true,
    voter: false,
  },
  __typename: "UI",
};

export const RoleActionsModel = {
  active: false,
  create: false,
  edit: false,
  delete: false,
  publish: false,
  show: false,
  __typename: "RoleActions",
};

export const UserRolModel = {
  timeZone: -6,
  language: "es",
  users: RoleActionsModel,
  voting: RoleActionsModel,
  parliamentary: RoleActionsModel,
  congressTopic: RoleActionsModel,
  politics: RoleActionsModel,
  __typename: "UserRol",
};

export const userModel = {
  response: responseModel,
  uidUser: "",
  names: "",
  surname1: "",
  surname2: "",
  icon: "",
  email: "",
  status: "INACTIVE",
  rol: UserRolModel,
  type: "UNKNOWN",
  phone: "",
  creatorUser: { uidUser: "", email: "" },
  parentUser: { uidUser: "", email: "" },
  utcCreationDate: "",
  __typename: "User",
};

export const voteModel = {
  response: responseModel,
  name: "",
  topic: "",
  image: "",
  briefDescription: "",
  broadDescription: "",
  extensiveDescription: "",
  creationDate: "",
  user: userModel,
  status: "INACTIVE",
  approvalStatus: "UNKNOWN",
  turn: "UNKNOWN",
  __typename: "Topic",
};

export const dataChamberModel = {
  response: responseModel,
  uidVoting: "",
  type: "DEPUTY",
  inFavor: 0,
  against: 0,
  abstention: 0,
  total: 0,
  approvalStatus: "UNKNOWN",
};

export const parliamentaryModel = {
  uidParliamentary: "",
  origin: "UNKNOWN",
  legislature: "",
  constitutionalReforms: 0,
  legalReforms: 0,
  decrees: 0,
  total: 0,
};

export const parliamentariansModel = {
  response: responseModel,
  count: 0,
  parliamentarians: [],
};

export const accessoryModel = {
  response: responseModel,
  uidAccessory: "",
  type: "LEGISLATIVEAGENDA",
  url: "",
};

export const accessoriesModel = {
  response: responseModel,
  accessories: [],
};

export const congressTopicModel = {
  response: responseModel,
  uidCongressTopic: "",
  topic: "",
  type: "UNKNOWN",
  status: 0,
  topics: [],
};

export const congressTopicsModel = {
  response: responseModel,
  topics: [],
};

export const congressMailboxModel = {
  response: responseModel,
  topic: "",
  topicChild: "",
  total: 0,
};

export const congressMailboxesModel = {
  response: responseModel,
  topics: [],
};

export const politicsModel = {
  response: responseModel,
  politics: [],
};
