import React, { useEffect, useState } from "react";

import withContainer from "../withContainer";
import { Accordion } from "../../components/accordion/Accordion";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import Terms from "./Terms";
import { Context } from "./Context";
import useGetPolitics from "../../Hooks/useGetPolitics";

const { Provider } = Context;
const nameParent = "accordion-politics";

const Politics = (props) => {
  const { language, userLoggedIn, history } = props;
  const [loading, setLoading] = useState(false);
  const { politics, loading: lP } = useGetPolitics();

  const [dataPrivacy, setDataPrivacy] = useState(undefined);
  const [cookies, setCookies] = useState(undefined);
  const [terms, setTerms] = useState(undefined);

  useEffect(() => {
    if (politics.politics.length > 0) {
      const dp = politics.politics.filter((p) => p.type === "PRIVACY")[0];
      if (dp) {
        setDataPrivacy(dp);
      }

      const dc = politics.politics.filter((p) => p.type === "COOKIES")[0];
      if (dc) {
        setCookies(dc);
      }

      const dt = politics.politics.filter((p) => p.type === "TERMS")[0];
      if (dt) {
        setTerms(dt);
      }
    }
  }, [politics]);

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !userLoggedIn.rol.politics.active) {
      history.push("/");
    }
  }, [history, userLoggedIn]);

  return (
    <Provider
      value={{
        loading: lP || loading,
        setLoading,
        dataPrivacy,
        setDataPrivacy,
        cookies,
        setCookies,
        terms,
        setTerms,
      }}
    >
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{language.txt.inicio}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language.txt.politicas}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">{language.txt.politicas}</h1>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-12">
            <Accordion name={nameParent}>
              <Privacy {...props} nameParent={nameParent} />
              <Cookies {...props} nameParent={nameParent} />
              <Terms {...props} nameParent={nameParent} />
            </Accordion>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default withContainer(Politics)({});
