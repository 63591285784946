import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import withContainer from "../withContainer";
import { Accordion } from "../../components/accordion/Accordion";
import ChamberDeputies from "./ChamberDeputies";
import SenateChamber from "./SenateChamber";
import { LoadingScreen } from "../../components/messages/Messages";
import Voting from "./Voting";
import { voteModel } from "../../apollo/ContextApp";
import { Context } from "./Context";
import useGetTopic from "../../Hooks/useGetTopic";

const { Provider } = Context;

const EditVote = (props) => {
  const { userLoggedIn, history, language } = props;
  const [vote, setVote] = useState(voteModel);

  const { topic, loading: lTopic } = useGetTopic({
    uidUser: userLoggedIn.uidUser,
    uidVoting: props.match.params.id,
  });

  const margeVote = (newVote) => {
    setVote({
      ...vote,
      ...newVote,
    });
  };

  useEffect(() => {
    if (topic.uidVoting !== "" && !lTopic) {
      setVote(topic);
    }
  }, [topic]);

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !userLoggedIn.rol.voting.active) {
      history.push("/");
    }
  }, [history, userLoggedIn]);
  if (lTopic) {
    return "";
  }
  return (
    <Provider
      value={{
        vote,
        margeVote,
        loading: lTopic,
        topic,
      }}
    >
      <div className="container pt-4 mb-5">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{language.txt.inicio}</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/votaciones">{language.txt.adminVotacion}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language.txt.editar}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h1 className="fw-bold text-dark h2">
              {language.txt.editarVotacion}
            </h1>
          </div>
        </div>
        <LoadingScreen userLoggedIn={userLoggedIn} loading={lTopic} />
        <div className="card">
          <Accordion name="accordion-voting">
            <Voting
              {...props}
              nameParent={"accordion-voting"}
              textBtn={language.txt.actualizar}
            />

            {vote.turn === "DEPUTY" ? (
              <ChamberDeputies
                {...props}
                nameParent={"accordion-voting"}
                textBtn={language.txt.actualizar}
              />
            ) : (
              <SenateChamber
                {...props}
                nameParent={"accordion-voting"}
                textBtn={language.txt.actualizar}
              />
            )}
          </Accordion>
        </div>
      </div>
    </Provider>
  );
};

export default withContainer(EditVote)({});
