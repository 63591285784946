import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import withContainer from "../withContainer";
import { InputArea, Input } from "../../components/inputs/Inputs";
import InputFile from "../../components/inputs/InputFile";
import InputFileDragAndDropImg from "../../components/inputs/InputFileDragAndDropImg";
import {
  isAlphaAccents,
  isValidField,
  esNumero,
} from "../../utils/validations";
import { CatTurns } from "../../components/catalogs";
import { voteModel } from "../../apollo/ContextApp";
import { MessageAction } from "../../components/messages/Messages";
import useUpload from "../../Hooks/useUpload";
import useAddTopic from "../../Hooks/useAddTopic";

const { REACT_APP_URL_IMG } = process.env;

const CreateVote = (props) => {
  const { language, history, userLoggedIn } = props;
  const [vote, setVote] = useState(voteModel);
  const [fileImg, setFileImg] = useState({
    file: undefined,
    response: { code: 0, message: "" },
    url: "-",
    name: "",
  });
  const [broadDescription, setBroadDescription] = useState({
    file: undefined,
    response: { code: 0, message: "" },
    url: "-",
    name: "",
  });
  const [extensiveDescription, setExtensiveDescription] = useState({
    file: undefined,
    response: { code: 0, message: "" },
    url: "-",
    name: "",
  });
  const [msnImg, setMsnImg] = useState({ message: "", code: 200 });
  const [msnBroad, setMsnBroad] = useState({ message: "", code: 200 });
  const [msnExtensive, setMsnExtensive] = useState({ message: "", code: 200 });
  const [message, setMessage] = useState({ message: "", code: 200 });

  const { addTopic, loading: lAddTopic } = useAddTopic({
    onComplete: (createTopic) => {
      setMessage({ ...createTopic.response });
      if (createTopic.response.code === 200) {
        setMessage({
          message:
            createTopic.response.message + ", redirigiendo, espere por favor",
          code: 200,
        });
        setTimeout(() => {
          history.push("/votaciones/editar/" + createTopic.uidVoting);
        }, 1000);
      }
    },
  });

  const { upload, loading: lUpload } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setFileImg({
          ...fileImg,
          url: uploadFile.url,
        });
        setVote({
          ...vote,
          image: uploadFile.url,
        });
      }
      setMsnImg({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnImg({ message: "", code: 200 });
      }, 3000);
    },
  });
  const { upload: uploadBroad, loading: lUploadBroad } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setBroadDescription({
          ...broadDescription,
          url: uploadFile.url,
        });
        setVote({
          ...vote,
          broadDescription: uploadFile.url,
        });
      }
      setMsnBroad({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnBroad({ message: "", code: 200 });
      }, 3000);
    },
  });

  const { upload: uploadExtensive, loading: lUploadExtensive } = useUpload({
    onComplete: (uploadFile) => {
      if (uploadFile.response.code === 200) {
        setExtensiveDescription({
          ...extensiveDescription,
          url: uploadFile.url,
        });
        setVote({
          ...vote,
          extensiveDescription: uploadFile.url,
        });
      }
      setMsnExtensive({
        message: uploadFile.response.message,
        code: uploadFile.response.code,
      });
      setTimeout(() => {
        setMsnExtensive({ message: "", code: 200 });
      }, 3000);
    },
  });

  const handleUpdateVote = (e) => {
    const { value, name } = e.target;
    setVote({
      ...vote,
      [name]: value,
    });
  };

  const handleImage = (files) => {
    setMsnImg({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
      setFileImg({
        file: files[0],
        response: { code: 0, message: "" },
        url: "-",
        name: files[0].name,
      });
      upload({ file: files[0], isPublic: true });
    } else {
      setFileImg({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnImg({ message: language.txt.errorformatoArchivoImg, code: 401 });
    }
  };

  const handleBroadDescription = (files) => {
    setMsnBroad({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "application/pdf") {
      setBroadDescription({
        file: files[0],
        response: { code: 0, message: "" },
        url: "",
        name: files[0].name,
      });
      uploadBroad({ file: files[0], isPublic: true });
    } else {
      setBroadDescription({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnBroad({ message: language.txt.errorformatoArchivoPDF, code: 401 });
    }
  };

  const handleExtensiveDescription = (files) => {
    setMsnExtensive({ message: language.txt.cargando, code: 200 });
    if (files[0].type === "application/pdf") {
      setExtensiveDescription({
        file: files[0],
        response: { code: 0, message: "" },
        url: "",
        name: files[0].name,
      });
      uploadExtensive({ file: files[0], isPublic: true });
    } else {
      setExtensiveDescription({
        file: undefined,
        response: { code: 0, message: "" },
        url: "-",
        name: "",
      });
      setMsnExtensive({
        message: language.txt.errorformatoArchivoPDF,
        code: 401,
      });
    }
  };

  const validateForm = (e) => {
    if (
      fileImg.url !== "-" &&
      broadDescription.url !== "-" &&
      extensiveDescription.url !== "-" &&
      isAlphaAccents(vote.topic).valid &&
      isAlphaAccents(vote.briefDescription).valid &&
      isAlphaAccents(vote.name).valid &&
      esNumero(vote.duration).valid &&
      vote.turn !== "UNKNOWN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage({ code: 200, message: language.txt.cargando });
    console.log(vote);
    addTopic({
      name: vote.name,
      topic: vote.topic,
      image: {
        url: fileImg.url,
        name: fileImg.name,
      },
      briefDescription: vote.briefDescription,
      broadDescription: {
        url: broadDescription.url,
        name: broadDescription.name,
      },
      extensiveDescription: {
        url: extensiveDescription.url,
        name: extensiveDescription.name,
      },
      duration: vote.duration,
      turn: vote.turn,
      uidUser: userLoggedIn.uidUser,
    });
  };

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !userLoggedIn.rol.voting.create) {
      history.push("/");
    }
  }, [history, userLoggedIn]);

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">{language.txt.inicio}</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/votaciones">{language.txt.adminVotacion}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {language.txt.crear}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-md-12 d-flex justify-content-between align-items-center">
          <h1 className="fw-bold text-dark h2">
            {language.txt.agregarVotacion}
          </h1>
        </div>
      </div>
      <div className="row my-5 bg-white">
        <div className="col-md-12">
          <form
            onSubmit={onSubmit}
            className="mb-5"
            style={{ position: "relative" }}
          >
            <div className="row bg-white p-4">
              <div className="col-md-12 p-2">
                <p>{"📌" + language.txt.msnCrearEncuesta}</p>
                <hr className="dropdown-divider text-muted" />
              </div>
            </div>

            <div className="stikyNav d-flex justify-content-araund align-items-center">
              <button
                type="submit"
                className="btn btn-danger my-3"
                disabled={
                  !validateForm() ||
                  lAddTopic ||
                  lUpload ||
                  lUploadBroad ||
                  lUploadExtensive
                }
              >
                {language.txt.crear}
              </button>

              <div className="mx-3">
                <MessageAction message={message} />
              </div>
            </div>

            <div className="row bg-white p-4">
              <div className="col-md-12 p-2">
                <Input
                  name="name"
                  type="text"
                  label={language.txt.nombre}
                  textHelper={isAlphaAccents(vote.name).message}
                  value={vote.name}
                  isValid={isValidField(
                    vote.name,
                    isAlphaAccents(vote.name).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
            </div>
            <div className="row bg-white px-4">
              <div className="col-md-6 px-2">
                <Input
                  name="duration"
                  type="text"
                  label={language.txt.duracion}
                  icon="bi-person-fill"
                  textHelper={esNumero(vote.duration).message}
                  value={vote.duration}
                  isValid={isValidField(
                    vote.duration,
                    esNumero(vote.duration).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
              <div className="col-md-6 px-2">
                <CatTurns
                  language={language}
                  value={vote.turn}
                  onChange={handleUpdateVote}
                />
              </div>
            </div>
            <div className="row bg-white p-4">
              <div className="col-md-4 p-2">
                <div>
                  <InputFileDragAndDropImg
                    idInputFile={"idFileDragDropImgXML"}
                    dragCounter={0}
                    handleLoadFile={handleImage}
                    reset={false}
                    containerCustom={styles.containerImg}
                    ico={
                      fileImg.url !== "-"
                        ? fileImg.url
                        : REACT_APP_URL_IMG + "/camera.png"
                    }
                    language={language}
                  ></InputFileDragAndDropImg>
                  <MessageAction message={msnImg} />
                </div>
              </div>
              <div className="col-md-4 p-2">
                <InputArea
                  name="topic"
                  label={language.txt.temaParticipacion}
                  rows={10}
                  value={vote.topic}
                  textHelper={isAlphaAccents(vote.topic).message}
                  isValid={isValidField(
                    vote.topic,
                    isAlphaAccents(vote.topic).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
              <div className="col-md-4 p-2">
                <InputArea
                  name="briefDescription"
                  label={language.txt.briefDescription}
                  rows={10}
                  value={vote.briefDescription}
                  textHelper={isAlphaAccents(vote.briefDescription).message}
                  isValid={isValidField(
                    vote.briefDescription,
                    isAlphaAccents(vote.briefDescription).valid
                  )}
                  onChange={handleUpdateVote}
                />
              </div>
            </div>
            <div className="row bg-white p-4">
              <div className="col-md-4 p-2">
                <p>{language.txt.broadDescription}</p>
                <InputFile
                  {...props}
                  reset={false}
                  name="broadDescription"
                  handleLoadFile={handleBroadDescription}
                >
                  <p>
                    {broadDescription &&
                    broadDescription !== language.txt.errorformatoArchivoPDF
                      ? broadDescription.name
                      : ""}
                  </p>
                </InputFile>
                <MessageAction message={msnBroad} />
              </div>
              <div className="col-md-4 p-2">
                <p>{language.txt.extensiveDescription}</p>
                <InputFile
                  {...props}
                  reset={false}
                  name="extensiveDescription"
                  handleLoadFile={handleExtensiveDescription}
                >
                  <p>
                    {extensiveDescription &&
                    extensiveDescription !== language.txt.errorformatoArchivoPDF
                      ? extensiveDescription.name
                      : ""}
                  </p>
                </InputFile>
                <MessageAction message={msnExtensive} />
              </div>
              <div className="col-md-4 p-2"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withContainer(CreateVote)({});
