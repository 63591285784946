import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GET_TOPIC } from "./querys";
import { voteModel } from "../apollo/ContextApp";

const useGetTopic = ({ uidUser, uidVoting }) => {
  const [topic, setTopic] = useState(voteModel);
  const { data: qTopic, loading: lquery } = useQuery(GET_TOPIC, {
    variables: {
      uidUser,
      uidVoting,
    },
  });
  useEffect(() => {
    if (qTopic && qTopic.getTopic) {
      setTopic(qTopic.getTopic);
    }
  }, [qTopic]);
  return { topic, loading: lquery };
};

export default useGetTopic;
