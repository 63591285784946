import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { uiModel } from "../apollo/ContextApp";
import { GET_UI } from "../apollo/localQuerys";
import { updateUI } from "../apollo/Cache";

const useUI = () => {
  //const [loading, setLoading] = useState(false);
  //const [ui, setUi] = useState(uiModel);
  const { data, loading: lUI } = useQuery(GET_UI);
  /*
  useEffect(() => {
    if (data && data.ui) {
      setUi(data.ui);
      setLoading(false);
    }
  }, [data]);
  */
  return { ui: data.ui, updateUI, loading: lUI, uiModel };
};

export default useUI;
