import React from "react";

export const Context = React.createContext({
  loading: false,
  setLoading: () => {},
  dataPrivacy: {},
  setDataPrivacy: () => {},
  cookies: {},
  setCookies: () => {},
  terms: {},
  setTerms: () => {},
});
