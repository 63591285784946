import React, { useState, useEffect, useRef } from "react";

import styles from "./inputFiles.module.scss";

const InputFile = (props) => {
  const {
    reset,
    name,
    handleLoadFile,
    children,
    language,
    _drag,
    _dragCounter,
  } = props;
  const [drag, setDrag] = useState(_drag ? _drag : false);
  const [dragCounter, setDragCounter] = useState(
    _dragCounter ? _dragCounter : 0
  );
  const dropRef = useRef(undefined);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragCounter(1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragCounter === 0) {
      setDrag(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleLoadFile(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };
  const handleLoadFileInput = (e) => {
    e.preventDefault();
    const { files } = e.target;
    setDragCounter(1);
    handleLoadFile(files);
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      let div = dropRef.current;
      if (div) {
        div.removeEventListener("dragenter", handleDragIn);
        div.removeEventListener("dragleave", handleDragOut);
        div.removeEventListener("dragover", handleDrag);
        div.removeEventListener("drop", handleDrop);
        dropRef.current = undefined;
      }
    };
  }, []);

  return (
    <div id={`id-${name}`} className={`${styles.container}`} ref={dropRef}>
      <div className={`${styles.containerInput}`}>
        {dragCounter === 0 || reset ? (
          <>
            <div className={`${styles.containerInputFile}`}>
              <input
                type="file"
                name="file"
                id="field-file"
                className={`${styles.inputFile}`}
                onChange={handleLoadFileInput}
              />
              <label
                htmlFor="field-file"
                className="btn btn-danger text-white p-2 d-flex justify-content-center align-items-center"
              >
                <i
                  className={`bi bi-file-earmark-arrow-up me-2 ${styles.ico}`}
                ></i>
                <span>{language.txt.seleccionArchivo}</span>
              </label>
            </div>
            <p className="text-center my-4">
              {language.txt.msnSeleccioArchivo}
            </p>
          </>
        ) : (
          <i
            className={`bi-file-earmark-excel-fill text-danger ${styles.icoExel}`}
          ></i>
        )}

        {drag && (
          <div>
            <div>
              <p>{language.txt.soltarArchivo}</p>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default InputFile;
