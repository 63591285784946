import React, { useState, useEffect } from "react";
import XLSX from "xlsx";

import withContainer from "../../withContainer";
import ListUsers from "./ListUsers";
import { Accordion } from "../../../components/accordion/Accordion";
import useLazySearchUsers from "../../../Hooks/useLazySearchUsers";

const Users = (props) => {
  const { language, history, userLoggedIn } = props;
  const [exportXls, setExportXls] = useState(false);
  const [search, setSearch] = useState(undefined);
  const { rqUsers, users, loading: lUsers } = useLazySearchUsers();
  const [dataUsers, setDataUsers] = useState([]);

  const downloadExcel = () => {
    if (search) {
      setExportXls(true);
      rqUsers({
        variables: {
          search,
        },
      });
    }
  };

  const updateCountUser = (search) => {
    setSearch(search);
  };

  const exportFile = (users) => {
    //if (exportXls) {
    let jDatos = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];

      jDatos.push({
        Nombres: user.names,
        ["Apellido paterno"]: user.surname1,
        ["Apellido materno"]: user.surname2,
        ["Correo electrónico"]: user.email,
        Estado: user.status,
        Tipo: user.type,
        ["Teléfono"]: user.phone,
      });
    }

    const ws = XLSX.utils.json_to_sheet(jDatos);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
    XLSX.writeFile(wb, `Usuarios_${new Date()}.xlsx`);
    //}
    setExportXls(false);
  };

  useEffect(() => {
    if (!lUsers && users.users.length > 0) {
      setDataUsers(users.users);
      exportFile(users.users);
    }
  }, [lUsers, users]);

  useEffect(() => {
    if (dataUsers.length > 0 && exportXls && !lUsers) {
      exportFile(dataUsers);
    }
  }, [exportXls]);

  useEffect(() => {
    if (userLoggedIn.uidUser !== "" && !userLoggedIn.rol.users.active) {
      history.push("/");
    }
  }, [history, userLoggedIn]);

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">{language.txt.inicio}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {language.txt.usuarios}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between align-items-center">
          <h1 className="fw-bold text-dark h2">{language.txt.usuarios}</h1>

          {userLoggedIn.rol.users.create ? (
            <a href="/usuarios/crear" className="fw-bold btn btn-danger btn-sm">
              <i className="bi-plus-circle fw-bold me-2"></i>
              <span>{language.txt.agregarUsuario}</span>
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12 d-flex justify-content-start align-items-center">
          {exportXls ? (
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span
              className="fw-bold pe-auto me-3"
              onClick={downloadExcel}
              disabled={lUsers}
              role="button"
            >
              <i className="bi-download fw-bold me-2"></i>
              {language.txt.descargar}
            </span>
          )}
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12">
          <ListUsers {...props} updateCountUser={updateCountUser} />
        </div>
      </div>
    </div>
  );
};

export default withContainer(Users)({});
