import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($dataUser: CreateUser!) {
    createUser(dataUser: $dataUser) {
      response {
        code
        message
      }
      uidUser
    }
  }
`;

export const EDIT_PI = gql`
  mutation EditPersonalInformation($dataUser: DataPIUser!) {
    editPersonalInformation(dataUser: $dataUser) {
      code
      message
    }
  }
`;

export const EDIT_ROL = gql`
  mutation EditRol($uidUser: String!, $rol: InUserRol!) {
    editRol(uidUser: $uidUser, rol: $rol) {
      code
      message
    }
  }
`;

export const CHANGE_AVATAR = gql`
  mutation ChangeAvatar($uidUser: String!, $icon: String!) {
    changeAvatar(uidUser: $uidUser, icon: $icon) {
      code
      message
    }
  }
`;
