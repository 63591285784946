import React, { useEffect, useState } from "react";
import XLSX from "xlsx";

import withContainer from "../withContainer";
import PermissionDenied from "../main/PermissionDenied";
import ListVoting from "./ListVoting";
import useLazySearchTopics from "../../Hooks/useLazySearchTopics";

const Votaciones = (props) => {
  const { language, history, userLoggedIn } = props;
  const [search, setSearch] = useState(undefined);
  const { rqTopics, topics, loading: lTopics } = useLazySearchTopics();
  const [exportXls, setExportXls] = useState(false);
  const [dataTopic, setDataTopic] = useState([]);

  const downloadExcel = () => {
    if (search) {
      setExportXls(true);
      rqTopics({
        variables: {
          search,
        },
      });
    }
  };

  const updateCountTopic = (search) => {
    setSearch(search);
  };

  const exportFile = (topics) => {
    //if (exportXls) {
    let jDatos = [];
    for (let i = 0; i < topics.length; i++) {
      const topic = topics[i];

      jDatos.push({
        Nombre: topic.name,
        Tema: topic.topic,
        Imagen: topic.image,
        ["Descripción breve"]: topic.briefDescription,
        ["Descripción amplia"]: topic.broadDescription,
        ["Descripción extensa"]: topic.extensiveDescription,
        ["Duración Hrs"]: topic.duration,
        ["Fecha de creación"]: topic.creationDate,
        Usuario: topic.user.names + topic.user.surname1,
        Estado:
          topic.status === "INACTIVE"
            ? language.txt.inactiva
            : topic.status === "DRAF"
            ? language.txt.borrador
            : topic.status === "PUBLISH"
            ? language.txt.publicada
            : language.txt.desconocido,
        ["Estado de aprovación"]:
          topic.approvalStatus === "NOTAPPROVED"
            ? language.txt.noAprobada
            : language.txt.aprobada,
      });
    }

    const ws = XLSX.utils.json_to_sheet(jDatos);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Votaciones");
    XLSX.writeFile(wb, `Votaciones_${new Date()}.xlsx`);
    //}
    setExportXls(false);
  };

  useEffect(() => {
    if (!lTopics && topics.topics.length > 0) {
      setDataTopic(topics.topics);
      exportFile(topics.topics);
    }
  }, [lTopics, topics]);

  useEffect(() => {
    if (dataTopic.length > 0 && exportXls && !lTopics) {
      exportFile(dataTopic);
    }
  }, [exportXls]);

  return (
    <>
      {!userLoggedIn.rol.voting.active ? (
        <PermissionDenied {...props} />
      ) : (
        <div className="container pt-4">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{language.txt.inicio}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {language.txt.adminVotacion}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <h1 className="fw-bold text-dark h2">
                {language.txt.votaciones}
              </h1>
              {userLoggedIn.rol.voting.create ? (
                <a
                  href="/votaciones/crear"
                  className="fw-bold btn btn-danger btn-sm"
                >
                  <i className="bi-plus-circle fw-bold me-2"></i>
                  <span>{language.txt.agregarVotacion}</span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12 d-flex justify-content-start align-items-center">
              {exportXls ? (
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span
                  className="fw-bold pe-auto me-3"
                  onClick={downloadExcel}
                  disabled={lTopics}
                  role="button"
                >
                  <i className="bi-download fw-bold me-2"></i>
                  {language.txt.descargar}
                </span>
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12">
              <ListVoting {...props} updateCountTopic={updateCountTopic} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withContainer(Votaciones)({});
