export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const isAlphanumericEGCRegex = /[^A-Za-z0-9\s\-\_\w.*+\?^${}()|[\]\\#|!¿%&=?¿¡_]/;
export const isAlphaAccentsRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s\-\_\w.*+\?^${}()|[\]\\#|!¿%&=?¿¡_,]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
export const isNumberRegex = /[^z0-9]/;
export const isMayus = /^(?=.*[A-Z])/;
export const isMinus = /^(?=.*[a-z])/;
export const isSpecial = /[.*+\?^${}()|[\]\\#|!¿%&=?¿¡_]/;
export const isNumeros = /^(?=.*[0-9])/;
export const islen = /^(?=.{6,})/;
export const isText = /[']*$/;

/**
 * Validación de correo electrónico
 * @param {*} value
 */
export const isEmail = (value) => {
  let isValid = false;
  isValid =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    emailRegex.test(value)
      ? true
      : false;
  return { valid: isValid, message: "Correo no valido" };
};

/**
 * Valid que value sea diferente de vacio, undefined o null
 * @param {*} value
 */
export const isValidField = (value, valid) => {
  let isValid = false;
  isValid = value === "" || value === undefined || value === null || valid;
  return isValid;
};

/**
 * Valida que solo se ingresen valores alfa númericos, espacios, guiones y ciertos caracteres espaciales
 * (!@#$%&?¿{}*)
 * @param {*} value
 */
export const isAlphanumericEGC = (value) => {
  let isValid = false;
  isValid =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    !isAlphanumericEGCRegex.test(value)
      ? true
      : false;
  return {
    valid: isValid,
    message: "Se esperaba un valor alfanúmerico (-,_)",
  };
};

/**
 * Valida que solo se ingresen valores alfa númericos con acentos
 * @param {*} value
 */
export const isAlphaAccents = (value) => {
  let isValid = false;
  isValid =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    isAlphaAccentsRegex.test(value)
      ? true
      : false;
  return { valid: isValid, message: "Solo acepta letras espacios y acentos" };
};

/**
 * Valida que value es un número
 * @param {*} value
 */
export const esNumero = (value) => {
  let isValid = false;
  isValid =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    !isNumberRegex.test(value)
      ? true
      : value === 0
      ? true
      : false;
  return { valid: isValid, message: "Se esperaba un número" };
};

/**
 * Política de password, verifica que tan seguro es una contraseña según el criterio establecido
 * Debe contener mayúsculas, minúsculas, números, caracteres escpeciales y al menos debe se de longitud 6
 * @param {*} value
 */
export const verifyPasswords = (pass) => {
  let nivel = 0;

  if (pass && pass !== "") {
    if (isMayus.test(pass) || isMinus.test(pass)) {
      nivel = 1;
    }

    if (
      (isMayus.test(pass) || isMinus.test(pass)) &&
      isNumeros.test(pass) &&
      islen.test(pass)
    ) {
      nivel = 2;
    }

    if (
      (isMayus.test(pass) || isMinus.test(pass)) &&
      isSpecial.test(pass) &&
      islen.test(pass)
    ) {
      nivel = 2;
    }

    if (
      (isMayus.test(pass) || isMinus.test(pass)) &&
      isNumeros.test(pass) &&
      isSpecial.test(pass) &&
      islen.test(pass)
    ) {
      nivel = 3;
    }
  }

  return nivel;
};
